<template>
  <div>
    <div class="section">
      <CreateHeader :useRouteMeta="true" />
    </div>
    <div class="section">
      <Timeframe :disableDayparts="$route.name.includes('report')" :maxDate="maxDate" />
    </div>
  </div>
</template>

<script>
import CreateHeader from "./CreateHeader.vue";
import Timeframe from "../../../dataExplorer/Timeframe.vue";
import moment from "moment";

export default {
  components: { CreateHeader, Timeframe },
  computed: {
    maxDate() {
      if (this.$route.name.includes('visits-report')) {
        return moment().add(1, "year").format("YYYY-MM-DD");
      } else {
        return moment().subtract(2, "days").format("YYYY-MM-DD");
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>