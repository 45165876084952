<template>
  <ChartCard :isLoading="isLoading" :isLoadingToLocation="isLoadingToLocation" id="est">
    <template #icon>
      <img
        :src="require('pinnacle-lib/assets/icon/foot-traffic-icon.svg')"
        style="height: 50px; width: 50px"
        alt="foot"
      />
    </template>
    <template #title>
      <h2>
        Estimated Visits Per
        {{ chartType.month === "bar" ? label.bar : label.line }}
      </h2>
      <p>
        An estimate of the real-world number of visits made to the specific
        location
      </p>
    </template>
    <template #dropdown>
      <b-dropdown
        id="est-options-menu"
        ref="estOptionsMenu"
        style="margin-right: -20px"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        data-html2canvas-ignore="true"
      >
        <template #button-content>
          <img :src="require('pinnacle-lib/assets/icon/elip.svg')" alt="foot" />
        </template>
        <li>
          <b-dropdown id="export-submenu" class="dropdown-item" variant="link">
            <template #button-content> Export </template>
            <b-dropdown-item
              @click="saveChart('Estimated Visits Over Time', '#est')"
              >Download Image</b-dropdown-item
            >
            <b-dropdown-item
              @click="saveData('Estimated Visits Over Time', 'est')"
              >Download Data</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <li v-if="chartType.month === 'line'">
          <b-dropdown
            id="timeline-submenu"
            class="dropdown-item"
            variant="link"
          >
            <template #button-content> Timeline </template>
            <b-dropdown-item @click="changeTimescale('day')"
              >By Day</b-dropdown-item
            >
            <b-dropdown-item @click="changeTimescale('week')"
              >By Week</b-dropdown-item
            >
            <b-dropdown-item @click="changeTimescale('month')"
              >By Month</b-dropdown-item
            >
            <b-dropdown-item @click="changeTimescale('year')"
              >By Year</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <li>
          <b-dropdown
            id="chart-type-submenu"
            class="dropdown-item"
            variant="link"
          >
            <template #button-content> Chart Type </template>
            <b-dropdown-item @click="changeView({ month: 'line' })"
              >Line Chart</b-dropdown-item
            >
            <b-dropdown-item @click="changeView({ month: 'bar' })"
              >Bar Chart</b-dropdown-item
            >
          </b-dropdown>
        </li>
        <li>
        <div  v-if="chartType.month === 'line'" class="dropdown-checkbox">
          Range Band
          <b-checkbox style="margin-left: 10px;" @change="changeRange" v-model="showRange"></b-checkbox>
        </div>
        </li>
      </b-dropdown>
    </template>
    <template #name-card>
      <name-card
        chart-type="estFoot"
        v-bind:insufficient-data-ids="insufficientDataIds"
      ></name-card>
    </template>
    <template #charts-container>
      <div id="lineChart" v-if="chartType.month === 'line'">
        <line-chart
          ref="footTrafficLineChart"
          :chart-data="month.line.data"
          :options="month.line.option"
          :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
          :key="isLoading"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div id="barChart" v-if="chartType.month === 'bar'">
        <bar-chart
          ref="footTrafficBarChart"
          :chart-data="month.bar.data"
          :options="month.bar.option"
          :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
          :key="isLoading"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </template>
  </ChartCard>
</template>
<script>
import ChartCard from "./chartCard.vue";
import { isEqual } from "lodash";
import LineChart from "../charts/LineChart";
import BarChart from "../charts/BarChart";
import NameCard from "../partials/NameCard.vue";
import { bus } from "@/main";
import {
  getLineGraphOptions,
  getBarGraphOptions,
  momentParseDateFormats,
} from "@/config/chart";
import moment from "moment";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "../../services/charts/download.js";
import ChartUtilityMixin from "../../services/charts/chartUtil.js";
export default {
  components: {
    BarChart,
    LineChart,
    NameCard,
    ChartCard,
  },
  mixins: [ChartsDownloadMixin, MultiDropdownMixin, ChartUtilityMixin],
  data() {
    return {
      attempt: false,
      num: "",
      lineData: [],
      barData: [],
      insufficientDataIds: [],
      chartType: {
        month: "line",
        day: "bar",
        week: "bar",
      },
      month: {
        line: {
          data: null,
          option: null,
        },
        bar: {
          data: null,
          option: null,
        },
      },
      time: "",
      label: {
        line: "",
        bar: "",
      },
      isLoading: true,
      show: [true, true, true, true, true],
      showRange: true,
      showWarn: false,
      overrideTimescale: null,
      chartRefs: ["footTrafficLineChart", "footTrafficBarChart"]
    };
  },
  props: {
    filterString: {
      type: String,
    },
    id: {
      type: Array,
    },
  },
  methods: {
    changeRange(){
      this.loadChart();
    },
    saveCSV() {

      const { datasets } = this.month[this.chartType.month].data;

      const maxRows = Math.max(...datasets.map(set => (set.data.length)));

      const rows = datasets.find(set => {
        return set.data.length === maxRows;
      }).data.map((o) => (o.x));

      let raw = datasets.map((o) => (
        o.data
      ));
      
      const isInPreview = this.$route.path.split("/")[1] === "preview";
      const locations =
        isInPreview
          ? this.$sessionStore.state.study.previews
          : this.$sessionStore.state.study.locations;
      let output = `${this.label.line}\t${
        this.month.line.data.datasets[0].label
      }: ${this.$sessionStore.state.study.avgAddress.replace(/,/gi, "")}`;
      locations.map(
        (e, l) =>
          (output += `\t${this.month.line.data.datasets[l + 3].label.replace(
            /,/gi,
            ""
          )} ${e.address.replace(/,/gi, "")}`)
      );
      output += "\r\n";

      // get the average and the location values
      // 0 - median
      // 1 - maxLine
      // 2 - minLine
      // 3 - first location
      let idxArr = [0, 0, 0];
      locations.forEach(_ => {
        idxArr.push(0);
      });
      const LOCATION_IDX_OFFSET = this.chartType.month === "line" ? 3 : 1;

      for (let rowIdx = 0; rowIdx < maxRows; rowIdx++) {
        let line = "";

        const parse = (idx) => {
          const dataset = raw[idx];
          if (!dataset) return;
          const cursor = idxArr[idx];
          const data = dataset[cursor];
          if (data && data.y !== undefined && data.x !== undefined) {
            if (data.x === rows[rowIdx]) {
              if (line.slice(-2) !== "\t") {
                line += "\t";
              }
              line += data.y.toString();
              // Increment the indices in idxArr if used
              idxArr[idx] += 1;
            } else {
              line += "\t";
            }
          }
        }

        line += rows[rowIdx];

        parse(0);

        for (let i = 0; i < locations.length; i++) {
          parse(LOCATION_IDX_OFFSET + i);
        }
        
        line += "\r\n";
        output += line;
        
      }
      this.$sessionStore.commit("setEstChart", output);
    },
    changeView(view) {
      if (!isEqual(view, this.chartType)) {
        if (view === "bar") this.overrideTimescale = null;
        this.chartType = { ...this.chartType, ...view };
      }
    },
    changeTimescale(timescale) {
      if (timescale !== this.overrideTimescale) {
        this.overrideTimescale = timescale;
        this.label.line =
          timescale.charAt(0).toUpperCase() + timescale.slice(1);
        this.isLoading = true;
        this.loadChart();
      }
    },
    getTimeframe() {
      const filterSplit = this.filterString.split("&");
      const dateStart = moment(
        filterSplit[0].split("=")[1],
        momentParseDateFormats
      );
      const dateEnd = moment(
        filterSplit[1].split("=")[1],
        momentParseDateFormats
      );
      return { dateStart, dateEnd };
    },
    parseTimescale() {
      const { dateStart, dateEnd } = this.getTimeframe();
      const numMonths = dateEnd.clone().diff(dateStart, "months", true);
      const numWeeks = dateEnd.clone().diff(dateStart, "weeks", true);
      const numDays = dateEnd.clone().diff(dateStart, "days", true);
      if (numDays <= 22) {
        this.time = "LLL dd";
        this.num = numDays;
        return "day";
      } else if (numWeeks <= 14) {
        this.time = "LLL dd";
        this.num = numWeeks;
        return "week";
      } else if (numMonths <= 25) {
        this.time = "LLL yyyy";
        this.num = numMonths;
        return "month";
      } else {
        this.time = "yyyy";
        this.num = 4;
        return "year";
      }
    },
    async _requestData(dataType = "line") {
      let timescale = this.overrideTimescale;
      if (timescale === null || dataType === "bar") {
        timescale = this.parseTimescale();
        this.label[dataType] =
          timescale.charAt(0).toUpperCase() + timescale.slice(1);
      }
      const extraParams = {
        "aggregation": timescale
      };
      return await this.getChartData(`/v2/estimated-foot-traffic/${this.$route.params.ids}`, extraParams);
    },
    loadChart() {
      this.$nextTick(() => {
        this.requestData().then((res) => {
          this.setData(res);
        });
      });
    },
    createBackgroundColorExpr(locationIdx, color) {
      const getOpacityHexValue = (origDate, diffDate) => {
        const daysBetween = Math.abs(origDate.diff(diffDate, "days", true));
        return daysBetween > 0 ? "a0" : "ff";
      };

      const timescale = this.parseTimescale();

      return (context) => {
        const { dataIndex } = context;
        if (
          !("dataset" in context) ||
          !("data" in context.dataset) ||
          !(dataIndex in context.dataset.data)
        )
          return color[locationIdx];
        const record = context.dataset.data[dataIndex];
        if (!record) return color[locationIdx];
        const { dateStart, dateEnd } = this.getTimeframe();
        let hex = color[locationIdx];
        if (dataIndex === 0 && timescale !== "day") {
          // Opacity logic for first bar
          const firstOf = dateStart.clone().startOf(timescale);
          hex += getOpacityHexValue(dateStart, firstOf);
        } else if (
          dataIndex === context.dataset.data.length - 1 &&
          timescale !== "day"
        ) {
          const lastOf = dateEnd.clone().endOf(timescale);
          hex += getOpacityHexValue(dateEnd, lastOf);
        }
        return hex;
      };
    },
    addStudyInformation(monthEntries, target = "line") {
      const { color } = this.chartColors;

      for (let i = 0; i < this.id.length; i++) {
        let polygon = {};
        let enough_data = !this.insufficientDataIds.includes(this.id[i]);

        polygon = {
          label: this.location[i].shortenedName,
          data: monthEntries[this.id[i]].map(({ ...values }) => ({
            x: values.date,
            y: Math.round(values.estimated_visitors),
          })),
          borderColor: color[i],
          fill: false,
          spanGaps: false,
          hidden: !this.show[i] || !enough_data,
          categoryPercentage: this.num >= 24 ? 1 : this.num >= 12 ? 0.6 : 0.3,
          barPercentage: 1,
          datalabels: {
            display: false,
          },
        };
        if (target === "line") polygon.backgroundColor = color[i];
        else {
          polygon.backgroundColor = this.createBackgroundColorExpr(i, color);
        }
        this.month[target].data.datasets.push(polygon);
      }
    },
    parseData(data, cachedField = "lineData") {
      const series = data.series_data;
      if (!Object.entries(series).length) return;
      let median = data.aggregation_data.median_data
        ? data.aggregation_data.median_data
        : [];
      median = median.map(({ ...value }) => ({
        x: value.date,
        y: Math.round(value.estimated_visitors),
      }));
      if (cachedField === "lineData") {
        let { minLine, maxLine } = this.calcMinMax(this[cachedField]["series_data"], "estimated_visitors", "date");
        console.log(this[cachedField]["series_data"])
        minLine = minLine.map(({ ...value }) => ({
          x: value.date,
          y: Math.round(value.estimated_visitors),
        }));
        maxLine = maxLine.map(({ ...value }) => ({
          x: value.date,
          y: Math.round(value.estimated_visitors),
        }));
        return { median, minLine, maxLine };
      }
      return { median };
    },
    setData({ lineData, barData }) {
      if (typeof lineData === "undefined" || typeof barData === "undefined")
        return;

      const parsedLineData = this.parseData(lineData);
      const parsedBarData = this.parseData(barData, "barData");

      this.month = {
        line: {
          data: {
            datasets: [
              {
                label: this.medianName,
                data: parsedLineData.median,
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Max",
                data: parsedLineData.maxLine,
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Min",
                data: parsedLineData.minLine,
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
            ],
          },
          option: getLineGraphOptions({
            labelStringXAxes: this.label.line,
            labelStringYAxes: `Number of Visits / ${this.label.line}`,
            timeFrame: this.label.line.toLowerCase(),
          }),
        },
        bar: {
          data: {
            datasets: [
              {
                label: this.medianName,
                data: parsedBarData.median,
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                type: "line",
                datalabels: {
                  display: false,
                },
              },
            ],
          },
          option: getBarGraphOptions({
            labelStringXAxes: this.label.bar,
            labelStringYAxes: `Number of Visits / ${this.label.bar}`,
            timeFrame: this.label.bar.toLowerCase(),
            hideXAxes: true,
          }),
        },
      };
      this.addStudyInformation(lineData.series_data, "line");
      this.addStudyInformation(barData.series_data, "bar");
      this.saveCSV();
      this.isLoading = false;
      if (
        this.$refs.footTrafficLineChart &&
        this.$refs.footTrafficLineChart.render
      ) {
        this.$refs.footTrafficLineChart.render();
      }
    },
    checkPercentMatches(metadata) {
      const dataPercents = metadata.data_percents;
      const threshold = 0.01;
      if (dataPercents) {
        let hasEnoughData = false;
        const tmp = [];
        Object.entries(dataPercents.series).forEach((o) => {
          const polygonId = o[0];
          if (o[1].percent_matches > threshold) {
            hasEnoughData = true;
          } else {
            tmp.push(polygonId);
          }
        });
        this.insufficientDataIds = tmp;
        if (!hasEnoughData) {
          this.showWarn = true;
          this.isLoading = false;
          return;
        }
      }
    },
    async requestData() {
      this.showWarn = false;
      // Beware, promise order matters here because of how this.label works
      const promises = [this._requestData("bar"), this._requestData("line")];
      const responses = await Promise.allSettled(promises);

      // Check the percent_matches, we can assume the line and bar data are the same in terms of percent matching
      this.checkPercentMatches(responses[1].value.data.meta_data);

      this.lineData = responses[1].value.data;
      this.barData = responses[0].value.data;

      return {
        lineData: responses[1].value.data,
        barData: responses[0].value.data,
      };
    },
  },
  mounted() {
    this.registerMultiDropdown(this.$refs.estOptionsMenu);
    let ids = this.id;
    let tempArr = [true, true, true, true, true];
    Object.entries(this.disabledIds).forEach(function (o) {
      if (ids.indexOf(o[0]) !== undefined) {
        tempArr[ids.indexOf(o[0])] = false;
      }
    });
    this.show = tempArr;
    // this.filterString = `start_date=01/01/2021&end_date=01/16/2021`
    //this.loadChart();  // Wait until filters event before populating chart
    bus.$on("locationsListChanged", (res) => {
      const rangeBandData = this.calcMinMax(this.lineData.series_data, "estimated_visitors", "date");
      this.handleLocationsListChanged(res, this.month, rangeBandData, (line) => {
        return line.map(({...values}) => ({
          x: values.date,
          y: Math.round(values.estimated_visitors)
        }));
      });
      this.renderChartRefs();
    });
    bus.$on("filters", (res) => {
      this.isLoading = true;
      if (this.isLoadingToLocation === false) {
        this.loadChart();
      } else {
        //re-scope this to that for sessionstore
        this.attempt = true;
      }
    });

    bus.$on("onHover", (res) => {
      if (!this.month.line.data) return false;
      if (!this.month.bar.data) return false;
      //This function manually sets colors of each dataset on hover
      //This index is used to skip the first 3 datasets (median, min, max)
      let index = this.id.indexOf(res.selected) + 3;
      const { color, altColor, medColor, medAltColor } = this.chartColors;
      if (res.value) {
        for (let i = 0; i < this.id.length; i++) {
          if (i === index - 3) continue;
          this.month.line.data.datasets[i + 3].borderColor = altColor[i];
          this.month.line.data.datasets[i + 3].backgroundColor = altColor[i];
          this.month.bar.data.datasets[i + 1].borderColor = altColor[i];
          this.month.bar.data.datasets[i + 1].backgroundColor = altColor[i];
        }

        this.month.line.data.datasets[0].borderColor = medAltColor;
        this.month.line.data.datasets[0].backgroundColor = medAltColor;
        this.month.bar.data.datasets[0].borderColor = medAltColor;
        this.month.bar.data.datasets[0].backgroundColor = medAltColor;

        if (res.selected === "median") {
          this.month.line.data.datasets[0].borderColor = medColor;
          this.month.line.data.datasets[0].backgroundColor = medColor;
          this.month.bar.data.datasets[0].borderColor = medColor;
          this.month.bar.data.datasets[0].backgroundColor = medColor;
        }
      } else {
        // When hover is removed
        for (let i = 0; i < this.id.length; i++) {
          this.month.line.data.datasets[i + 3].borderColor = color[i];
          this.month.line.data.datasets[i + 3].backgroundColor =
            this.createBackgroundColorExpr(i, color);
          this.month.bar.data.datasets[i + 1].borderColor = color[i];
          this.month.bar.data.datasets[i + 1].backgroundColor =
            this.createBackgroundColorExpr(i, color);
        }
        this.month.line.data.datasets[0].borderColor = medColor;
        this.month.line.data.datasets[0].backgroundColor = medColor;
        this.month.bar.data.datasets[0].borderColor = medColor;
        this.month.bar.data.datasets[0].backgroundColor = medColor;
      }
      this.renderChartRefs();
    });
  },
  computed: {
    showMedian() {
      return this.$sessionStore.state.study.showMedian;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
  },
  watch: {
    isLoadingToLocation() {
      if (!this.isLoadingToLocation && this.attempt) {
        this.loadChart();
      }
    },
    showMedian() {

    },
  },
  // beforeDestroy() {
  //   console.log(this.unwatch())
  //   this.unwatch();
  // },
};
</script>

<style lang="scss" scoped>
@import "./chartStyles.scss";
</style>