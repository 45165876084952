<template>
  <div id="charts">
    <div class="chart-container" id="chart-container">
      <ReportTitle :reportDetails="reportDetails" :exportReport="exportReport" />

      <ReportEstFoot ref="est" :filterString="filterString" :studyLocations="studyLocations" />
      <ReportDayWeek ref="week" :filterString="filterString" :studyLocations="studyLocations" />
      <ReportMobileTradeArea ref="MobileTradeArea" :filterString="filterString" :studyLocations="studyLocations" />
      <ReportMarketShare ref="ReportMarketShare" :filterString="filterString" :studyLocations="studyLocations" />
      <ReportDemographics ref="ReportDemographics" :filterString="filterString" :studyLocations="studyLocations" />
    </div>
    <b-modal id="downloading" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc >
      <div class='downloadModal'>
        <vue-lottie-player
            autoplay
            loop
            mode="normal"
            :animationData="require('pinnacle-lib/assets/lottie/download-lottie.json')"
            width="46px"
            height="46px"
            name="download-lottie"
          />
      </div>
        <h1 class='downloadTitle' >Preparing Download</h1>
        <p class='downloadDesc'>We’re preparing your insights for downloading. Please wait for a moment while the process completes.</p>
    </b-modal>
</div>
</template>

<script>
import moment from "moment";
import { bus } from "@/main";
import { getISO3 } from "@/utils/countryMapping";

import ReportTitle from "./charts/ReportTitle.vue";
import ReportEstFoot from "./charts/ReportEstFoot.vue";
import ReportDayWeek from "./charts/ReportDayWeek.vue";
import ReportMobileTradeArea from "./charts/ReportMobileTradeArea.vue";
import ReportMarketShare from "./charts/ReportMarketShare.vue";
import ReportDemographics from "./charts/ReportDemographics.vue";

import * as dayjs from 'dayjs';
import JSZip from "jszip";
import { saveAs } from "file-saver";
//import html2canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
import { jsPDF } from "jspdf";

export default {
  components: {
    ReportTitle,
    ReportEstFoot,
    ReportDayWeek,
    ReportMobileTradeArea,
    ReportMarketShare,
    ReportDemographics,
  },
  props: ["reportDetails", "studyLocations"],
  data() {
    return {
      showMedian: true,
      // filters: {
      //   start_date: dayjs(this.reportDetails.start_date_time).format("MM/DD/YY"),
      //   end_date: dayjs(this.reportDetails.end_date_time).format("MM/DD/YY"),
      // },
    };
  },
  mounted() {
    const _t = this;

    // bus.$off("setFilters");
    // bus.$on("setFilters", (filters) => {
    //   this.setFilters(filters);
    // });
    this.$nextTick(() => {
      bus.$emit("chartBusReady");
    });
  },
  beforeDestroy(){
    //bus.$off("filters");
    bus.$off("locationsListChanged");
    bus.$off("comparisonCheck");
    bus.$off("onHover");
  },
  computed: {
    filterString() {
      if (this.reportDetails && this.reportDetails.start_date_time) {
        const start_date = dayjs(this.reportDetails.start_date_time).format("MM/DD/YY");
        const end_date = dayjs(this.reportDetails.end_date_time).format("MM/DD/YY");
        const filters = {
          start_date, end_date
        };
        const filterStringsArray = Object.entries(filters).map(
          (f) => `${f[0]}=${f[1]}`
        );
        return filterStringsArray.reduce(
          (acc, curr, i) =>
            `${acc}${curr}${i < filterStringsArray.length - 1 ? "&" : ""}`,
          ""
        );
      } else {
        return "";
      }
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
    },
    id() {
      let idArr = this.$route.params.id.split(",");
      return idArr || "";
    },
    locationInfo() {
      return this.location.shortenedName + " - " + this.location.address;
    },
  },
  watch: {},
  methods: {
    // setFilters(filters) {
    //   const _t = this;
    //   this.filters = { ...this.filters, ...filters };
    //   this.$nextTick(() => {
    //     bus.$emit("filters", this.filterString);
    //   });
    // },
    getCountryCode() {
      const countryCode = this.location.country_code;
      return getISO3(countryCode);
    },
    async exportReport() {
      this.$bvModal.show("downloading");
      let zip = new JSZip();
      const filter = (node)=>{
                const reviseClasses = ['keyHide'];
                const exclusionClasses = ['satelite-view', "mapboxgl-user-location-dot", 'mapboxgl-ctrl-bottom-right', 'insightsBtn', 'alert-container', "topbar-nav-container", "details-container", "container-fluid", "scroll", "absolute-container", "right-panel-datepicker", "closeInsightsBtn", "exportBtn"]; 
                if(node.classList === undefined){
                  return node;
                }
                if(node.classList.contains('keyHide'))
                {
                  let temp = node;
                  temp.className = 'key';
                  return temp;
                }
                return !exclusionClasses.some(classname=>node.classList.contains(classname));
              }
      let chartHeight = 135;
      let reportLayout = [
        {
          chartSelector:  "#ReportTilte",
          offset: 0,
          height: 25,
          page: 1,
        },
        {
          chartSelector:  ".placesDetailsCard",
          offset: 30,
          page: 1,
          width: 120,
          height: 120,
        },
        {
          chartSelector:  "#est",
          offset: 0,
          page: 2,
          csvRef: "est",
          csvFilename: "Estimated Visits Over Time.csv"
        },
        {
          chartSelector:  "#week",
          offset: 0,
          page: 3,
          csvRef: "week",
          csvFilename: "Visits by Day of Week.csv"
        },
        {
          chartSelector: "#MobileTradeArea",
          offset: 0,
          page: 4,
          csvRef: "MobileTradeArea",
          csvFilename: "Mobile Trade Area.csv"
        },
        {
          chartSelector: "#MarketShare",
          offset: 0,
          page: 5,
          csvRef: "ReportMarketShare",
          csvFilename: "Market Share.csv"
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Age",
          offset: 0,
          page: 6,
          height: 185 + chartHeight*5,
          csvRef: "ReportDemographics",
          csvFilename: "Demographics.zip"
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Income",
          offset: 0,
          page: 7,
          height: 185 + chartHeight*5
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Median Income",
          offset: 0,
          page: 8,
          height: 185 + chartHeight*5
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Median Home Value",
          offset: 0,
          page: 9,
          height: 185 + chartHeight*5
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Education",
          offset: 0,
          page: 10,
          height: 185 + chartHeight*5
        },
        {
          chartSelector: "#demographics",
          subSelector: "demochart-Ethnicity",
          offset: 0,
          page: 11,
          height: 185 + chartHeight*5
        },        
      ];
      
      const doc = new jsPDF({orientation: 'l', compress: true});
      doc.addPage();
      doc.addPage();
      doc.addPage();
      doc.addPage();
      
      doc.addPage();
      doc.addPage();
      doc.addPage();
      doc.addPage();
      doc.addPage();
      doc.addPage();
      
      for (let i = 0; i < reportLayout.length; i++) {
        let layoutItem = reportLayout[i];
        //console.log(layoutItem);
        let chartSelector = layoutItem.chartSelector;
        let offset = layoutItem.offset;
        let page = layoutItem.page;
        let width = layoutItem.width || 277;
        let height = layoutItem.height || 185;
        doc.setPage(page);
        let subfilter = filter;
        if (layoutItem.subSelector) {
          subfilter = (node)=>{
            console.log(node.id);
            if (node.id && node.id.includes('demochart-')) {
              if (node.id == layoutItem.subSelector) {
                return true;
              } else {
                return false;
              }
            }
            return filter(node);
          }
        }

        let blob = await htmlToImage.toPng(document.querySelector(chartSelector), {filter: subfilter,cacheBust: true});
        //console.log(chartSelector, offset, width, height);

        doc.addImage({
          imageData:blob, format:'PNG', 
          x: 10, 
          y:10 + offset, 
          width:width, 
          height:height
        });
        if (layoutItem.csvRef) {
          let data = this.$refs[layoutItem.csvRef].saveCSV();
          //console.log(data);
          zip.file(layoutItem.csvFilename, data);
        }
      }

      zip.file(`Report.pdf`, doc.output('blob'));
      this.$bvModal.hide("downloading");
      zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, 'Report.zip');
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #edf2f9;
  margin-top: 24px;
  margin-left: -25px;
  margin-right: -25px;
}
// .dateRangeDetails {
// position: absolute;
// margin-top: 10px;
// margin-left: -5px;
// }
#charts {
  // position: relative;
  position: absolute;
  // height: calc(100% - 150px);
  width: 100%;
  height: 100%;
  background: #f9fafc;
  padding: 1.5rem;
  overflow-y: scroll;
  
  ::v-deep.card {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 10px;
  }

  ::v-deep .title {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  ::v-deep .location {
    padding: 20px;
    margin-bottom: 12px;
    .location-info,
    .loc-name {
      flex: auto;
    }
    .location-info {
      padding: 0 15px;
    }
    .brand-img {
      width: 57px;
      height: 57px;
      background: #eaf2fd;
      padding: 16.5px;
      border-radius: 8px;
      margin-right: 15px;
    }
    p {
      margin-bottom: 0;
    }
    .name {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.01em;
      color: #12263f;
    }
    .address,
    .visitor {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
    }
    .address {
      color: #e5e6e9;
    }
    .visitor {
      color: #95aac9;
    }
    .visitor img {
      margin-right: 5px;
    }
    &.disabled {
      opacity: 0.3;
    }
  }

  ::v-deep .add-location-btn {
    width: 100%;
    height: 40px;
    background: #2c7be5;
    border: 1px solid #2c7be5;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-top: 7px;
    &:disabled {
      opacity: 0.3;
    }
  }
  .chart-container {
    flex: auto;

    .contact-card {
      height: 261px;
      padding: 0 75px 0 49px;
      margin-bottom: 20px;
      .content {
        flex: auto;
        padding: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.02em;
        color: #12263f;
        margin-bottom: 7px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: #e5e6e9;
        margin-bottom: 10px;
      }
      .contact-btn,
      .close-btn {
        width: 118px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.01em;
        padding: 0;
      }
      .contact-btn {
        background: #2c7be5;
        border: 1px solid #2c7be5;
        color: #ffffff;
        margin-right: 20px;
      }
      .close-btn {
        background: #ffffff;
        border: 1px solid #e5e6e9;
        color: #e5e6e9;
      }
      .img-container {
        width: 270px;
        height: 190px;
        position: relative;
      }
      .img-container img {
        position: absolute;
        top: 0;
        right: -100px;
      }
    }
  }
}
#timeFrameModal {
  .modal-content {
    width: 554px;
  }
  .modal-body {
    padding: 24px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #12263f;
    margin-bottom: 7px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #e5e6e9;
    margin-bottom: 20px;
  }
  select {
    width: 100%;
    height: 34px;
    padding: 0 10px;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #3b516c;
    margin-bottom: 20px;
    outline: 0;
  }
  .filter-btn {
    width: 115.59px;
    height: 41.9px;
    margin-left: auto;
    background: #2c7be5;
    border: 1px solid #2c7be5;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#viewDetailsModal {
  .modal-content {
    width: 596px;
  }
  .modal-body {
    padding: 24px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #12263f;
    margin-bottom: 22px;
  }
  .card {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 24px;
  }
}
.downloadModal{
  margin-left: 45%;
  margin-bottom: 20px;
}
.downloadTitle{
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  margin-left: 35%;
  color: #12263F;

}
.downloadDesc{
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  
  color: #6E84A3;
}
.primary-buttonB{
  width: 98%;
  border-radius: 4px;
  border-width: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #2c7be5;
  color: #ffffff;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>

