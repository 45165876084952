import { sessionStore } from '@/store/index.js';
import wkt from 'wkt';

export function createMarker({map, center, classString, iconURL, iconSize, iconBGColor, iconType, label, path, click_handler, mouseenter_handler, mouseleave_handler, loc, index, zIndex}) {
  const container = document.createElement('div');
  const imgContainer = document.createElement('div');
  const img = document.createElement('img');
  let size = '30px';
  let selected = null;

  if (iconSize !== undefined) size = iconSize;
  if (iconBGColor === undefined) iconBGColor = '#95AAC9';
  
  img.src = iconURL;

  img.style.display = 'none';

  img.style.color = 'white';
  img.style.width = '50%';
  img.style.height = '50%';
  if (iconType !== 'category') img.style.filter = 'brightness(0) invert(1)';
  container.className = 'location-pin';
  
  if (zIndex !== undefined) container.style.zIndex = zIndex;

  if (typeof loc !== "undefined") {
    container.className += ' location-pin-'+loc.id;
    
    // parse centroid if its a string
    if (typeof loc.centroid == "string") {
      loc.centroid = JSON.parse(loc.centroid);
    }
  }

  if (typeof index === "number") {
    container.className += ' location-pin-'+index;
  }

  if (classString !== undefined) {
    container.className += ' ' + classString;
  }

  // let selected = (comparisonLocations.some((selected) => selected.id === loc.id));
  if (sessionStore.state.study.locations) {
    let comparisonLocations = sessionStore.state.study.locations;
    selected = comparisonLocations.some((sel) => sel.id === loc.id);
    }

  container.onmouseenter = (evt) => {
    if (mouseenter_handler) mouseenter_handler(loc.id);
  }
  container.onmouseleave = (evt) => {
    if (mouseleave_handler) mouseleave_handler(loc.id);
  }  

  imgContainer.appendChild(img);

  imgContainer.onmouseenter = () => {
    if(!selected){
    $(container).addClass('result-highlight');
    img.style.display = 'flex';
    img.style.width = '50%';
    img.style.height = '50%';
    imgContainer.style.width = '24px';
    imgContainer.style.height = '24px';
    iconURL =  loc.avatar_subsector === "None" ||
    !loc.avatar_subsector.endsWith("svg")
      ? loc.avatar_sector
      : loc.avatar_subsector;
    img.src = iconURL;
  } 
  };

  imgContainer.onmouseleave = () => {
    if(!selected){
    $(container).removeClass('result-highlight');
    img.style.display = 'none';
    imgContainer.style.width = '18px';
    imgContainer.style.height = '18px';
    }
  };
  imgContainer.style.color = 'white';
  imgContainer.style.backgroundColor = iconBGColor;
  imgContainer.style.width = size;
  imgContainer.style.height = size;
  imgContainer.style.borderRadius = '50%';
  imgContainer.style.display = 'flex';
  imgContainer.style.justifyContent = 'center';
  imgContainer.style.alignItems = 'center';
  imgContainer.style.boxShadow = '0px 4px 4px 0px rgba(0, 0, 0, 0.5)';
  imgContainer.className = 'location-pin-icon';
  
//  imgContainer.onmouseenter = () => {
//    imgContainer.style.backgroundColor = '#247EE3';
//    if (mouseenter_handler !== undefined) {
//      mouseenter_handler(loc);
//    }
//  }
//  imgContainer.onmouseleave = () => {
//    imgContainer.style.backgroundColor = iconBGColor;
//    if (mouseleave_handler !== undefined) {
//      mouseleave_handler(loc);
//    }
//
//  }


//to change styling for study locations
if (sessionStore.state.study.locations && sessionStore.state.study.locations.some((sel) => sel.id === loc.id)) {
  img.style.display = 'flex';
  img.style.width = '50%';
  img.style.height = '50%';
  imgContainer.style.height = '24px';
  imgContainer.style.width = '24px';
  container.style.zIndex = '2';
}

  container.appendChild(imgContainer);
  if (label !== undefined) {
    const labelP = document.createElement('p');
    labelP.onmouseenter = () => {
      $(container).addClass('result-highlight');
    };
    labelP.onmouseleave = () => {
      $(container).removeClass('result-highlight');
    };
    labelP.style.fontSize = '13px';
    labelP.style.textShadow = '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff';
    labelP.style.lineHeight = 'normal';
    labelP.innerText = label;
    labelP.className = 'location-pin-label';
    container.appendChild(labelP);
  }
  if (path !== undefined) {
    container.onclick = () => {
      window.open(path);
    };
  }
  if (click_handler !== undefined) {
    container.onclick = () => {
      click_handler(loc); 
    }
  }
  const studyLocationMarker = new mapboxgl.Marker(container);

  // container.addEventListener('mouseenter', () => {
  //   if (!studyLocationMarker.getPopup().isOpen()) studyLocationMarker.togglePopup();
  // });
  // container.addEventListener('mouseleave', () => {
  //   if (studyLocationMarker.getPopup().isOpen()) studyLocationMarker.togglePopup();
  // });
  // const popup = new mapboxgl.Popup({closeButton: false, closeOnClick: false, anchor: 'bottom-left', offset: [15, -38]}).setHTML(generatePopupHTML(categoryURL, label, address));
  studyLocationMarker.setLngLat(center).addTo(map);
  return studyLocationMarker;
  
}
