const EveFilters = [
  { id: 'sector-filter', param: 'subsector_id', name: 'Industry' },
  { id: 'category-filter', param: 'category_id', name: 'Brand' },
  { id: 'country-filter', param: 'country_id', name: 'Country', criterionKey: 'cy' },
  { id: 'state-filter', param: 'province_id', name: 'Province (State for US)', criterionKey: 'st' },
  { id: 'dma-filter', param: 'municipality_id', name: 'Municipality (DMA for US)', criterionKey: 'dma' },
];


export default {
  created() {
    this.filters = EveFilters;
  },
  methods: {
    setSearchParams(term) {
      const numFilters = this.filters.length;
      if (!this._paramList) this._paramList = {};
      if (term !== "") {
        this._paramList['search_term'] = term;
        // this._paramList[`ands[${numFilters}].ors[0].or`] = `name~=${term}`;
        // this._paramList[`ands[${numFilters}].ors[1].or`] = `address~=${term}`;
        // this._paramList[`ands[${numFilters}].ors[2].or`] = `id~=${term}`;
      }
      this._setDefaultParams();
    },
    clearSearchParams() {
      if (!this._paramList) return;
      const numFilters = this.filters.length;
      delete this._paramList[`ands[${numFilters}].ors[0].or`];
      delete this._paramList[`ands[${numFilters}].ors[1].or`];
      delete this._paramList[`ands[${numFilters}].ors[2].or`];
    },
    clearAllParams() {
      this._paramList = {};
      this._selectedFilters = {};
    },
    _setDefaultParams() {
      if (!this._paramList) this._paramList = {};
      // this._paramList[`ands[8].and`] = 'type.name!=basic';
      // this._paramList[`ands[9].and`] = 'type.name!=Skipped';
      // this._paramList['include_polygon_category_children'] = true;
      // this._paramList['isInActive'] = true;
    },
    _setFilterParams() {
      if (!this._paramList) this._paramList = {};
      this._paramList.filter = []
      this.filters.forEach((filter, idx) => {
        if (this._selectedFilters[filter.id] !== undefined && this._selectedFilters[filter.id].length) {
          this._paramList.filter.push({
            field: filter.param,
            value: this._selectedFilters[filter.id].join('|')
          });
          //this._paramList[`ands[${idx}].and`] = `${filter.param}=${this._selectedFilters[filter.id].join('|')}`;
        } else {
          //delete this._paramList[`ands[${idx}].and`];
        }
      });
      this._paramList.filter = JSON.stringify(this._paramList.filter);
      this._setDefaultParams();
    },
    setOrderBy(field, asc) {
      if (field !== 'name' && field !== 'address') throw new Error('Invalid order by field');
      this._paramList['order_by[0]'] = `${field}:${asc ? 'asc' : 'desc'}`;
    },
    setFilter(filter, ids) {
      if (!this._selectedFilters) this._selectedFilters = {};
      this._selectedFilters[filter.id] = ids;
      this._setFilterParams();
    },
    hasUserParams() {
      return this._selectedFilters !== undefined && Object.keys(this._selectedFilters).length > 0 && (Object.values(this._selectedFilters).some(ids => ids.length > 0));
    },
    hasSearchParams() {
      if (!this._paramList)  return false;
      const numFilters = this.filters.length;
      
      return `ands[${numFilters}].ors[0].or` in this._paramList;
    },
    async getEveFilteredPolygons({page, page_size, overrideParams}) {
      console.log('eve getFilteredPolygons');
      let params;
      if (overrideParams !== undefined) params = overrideParams;
      else {
        params = {...this._paramList};
        if (page) params.page = page;
        if (page_size) params.page_size = page_size;
      }
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        const response = await this.$http({
          method: 'GET',
          params,
          ...headers,
          url: `${process.env.VUE_APP_PINNACLE_API}/v2/eve-polygons/polygons`
        });
        return response.data;
      } catch (axiosError) {
        throw axiosError;
      }
    },
    async searchCriteria(filter, query) {
      let path;
      const params = {};
      console.log(filter, query);

      // if (filter.param === 'polygon_category.id') {
      //   path = '/polygon-categories';
      // } else {
      //   path = '/criteria';
      //   params['where[0]'] = `type.key=${filter.criterionKey}`;
      // }
      if (query.name !== undefined) params[`search_term`] = query.name;
      //if (query.key !== undefined) params[`where[${Object.keys(params).length}]`] = `key${query.operator !== undefined ? query.operator : '='}${query.key}`;
      if (query.page_size) params.page_size = query.page_size;
      params[`field`] = filter.param;
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        const response = await this.$http({
          method: 'GET',
          ...headers,
          params,
          url: `${process.env.VUE_APP_PINNACLE_API}/v2/eve-polygons/options`
        });
        return response.data.records.slice();
      } catch (axiosError) {
        throw axiosError;
      }
    }
  }
}