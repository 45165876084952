<template>
  <fragment>
    <!-- <div v-if='location.length <= 1' class="location-brand-name">
        <div class="brand-img brand-avatar brand-avatar-40" 
          :style="brandImageContainerStyle"
        >
          <img :src="this.$sessionStore.state.study.locations[0].avatar_category" :style="brandImageStyle">
        </div>
        <div :title="this.$sessionStore.state.study.locations[0].shortenedName" class="brandShortenedName">{{ this.$sessionStore.state.study.locations[0].shortenedName }}</div>
        <div :title="this.$sessionStore.state.study.locations[0].address" class="brand-name">| {{ this.$sessionStore.state.study.locations[0].address }}</div>
        <div class='circle' />
    </div> -->

    <div v-if="chartType !== 'origin'" class="location-brand-name">
      <!-- v-if='location.length >= 2' -->
      <div
        v-bind:class="{ disabled: !value.show }"
        @mouseover="hoverHandler(value.id, true)"
        @mouseleave="hoverHandler(value.id, false)"
        @click="clickHandler(value.id)"
        :title="`${value.name}: ${value.address} ${value.city}, ${value.province}, ${value.postal1}`"
        class="brandShortenedName"
        v-for="(value, index) in location"
        v-bind:key="value.id"
      >
        <div>
          <div v-if="!hasInsufficientData(value.id)" class="circleN" />
        </div>
        <div
          v-b-tooltip.hover.top="'Data unavailable for the selected dates'"
          v-if="hasInsufficientData(value.id)"
        >
          <img
            class="not-enough-data-icon"
            :src="require('pinnacle-lib/assets/icon/alert-triangle.svg')"
          />
        </div>
        <p class="text">{{ value.name }}</p>
      </div>
      <div
        v-if="medianName()"
        v-bind:class="{ hidden: !median }"
        @mouseover="hoverHandler(string, true)"
        @mouseleave="hoverHandler(string, false)"
        class="brandShortenedName"
      >
        <div><div class="circleM" /></div>
        <p class="text">{{ medianName() }}</p>
      </div>
    </div>
    <!-- This one is only for origin market chart -->
    <div v-if="chartType === 'origin'" class="location-brand-name hover">
      <div
        :id="'button' + index"
        v-bind:class="{ hidden: !display[index] }"
        @click="clickHandler(`${index + 1}. ${value.shortenedName}`)"
        :title="value.address"
        class="brandShortenedName"
        v-for="(value, index) in location"
        v-bind:key="`${index + 1}. ${value.shortenedName}`"
      >
        <div>
          <div v-if="!hasInsufficientData(value.id)" class="circleN" />
        </div>
        <div
          v-b-tooltip.hover.top="'Data unavailable for the selected dates'"
          v-if="hasInsufficientData(value.id)"
        >
          <img
            class="not-enough-data-icon"
            :src="require('pinnacle-lib/assets/icon/alert-triangle.svg')"
          />
        </div>
        <p class="text">{{ value.shortenedName }}</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import { bus } from "@/main";
export default {
  components: { Fragment },
  data() {
    return {
      brandImageStyle: {
        width: "100%",
        height: "100%",
      },
      brandImageContainerStyle: {},
      display: [true, true, true, true, true],
      median: false,
      string: "median",
      name: "Avg Place",
    };
  },
  props: {
    insufficientDataIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    chartType: {
      type: String,
    },
    studyLocations: {
      type: Array,
      default: function () {
        return [];
      },      
    },
  },
  methods: {
    hoverHandler(id, bool) {
      bus.$emit("onHover", {
        selected: id,
        value: bool,
      });
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
    clickHandler(id) {
      //console.log("clickHandler", id);
      let match = this.studyLocations.find(l => l.id == id);
      //console.log(this.studyLocations);
      if (match) {
        match.show = !match.show;
      }
      
      // bus.$emit("onClick", {
      //   selected: id,
      // });
    },
    average() {
      return this.$sessionStore.state.study.bool;
    },
  },
  mounted() {
    let ids = this.id;
    let tempArr = [true, true, true, true, true];
    Object.entries(this.disabledIds).forEach(function (o) {
      if (ids.indexOf(o[0]) !== undefined) {
        tempArr[ids.indexOf(o[0])] = false;
      }
    });
    this.display = tempArr;
    if (this.$sessionStore.state.study.bool) {
      this.median = this.$sessionStore.state.study.bool;
    }
    bus.$on("locationsListChanged", (res) => {
      if (res.locationId === "median") {
        this.median = res.selected;
      }
      let index = this.id.indexOf(res.locationId);
      this.$set(this.display, index, res.selected);
    });
    if (this.location && this.getCategoryAvatar(this.location)) {
      this.brandImageStyle = this.getImgStyle(this.location);
      this.brandImageContainerStyle = this.getImgContainerStyle(this.location);
    } else {
      this.brandImageStyle = {
        width: "100%",
        height: "100%",
      };

      this.brandImageContainerStyle = {};
    }
  },
  computed: {
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    location() {
      //console.log(this.studyLocations);
      //console.log("session", this.$sessionStore.state.study.locations);
      return this.studyLocations;
      //this will check if route is /preview, and pull from the appropriate vuex store
      // return this.$route.path.split("/")[1] === "preview"
      //   ? this.$sessionStore.state.study.previews
      //   : this.$sessionStore.state.study.locations;
    },
    id() {
      let idArr = this.$route.params.id.split(",");
      return idArr || "";
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    hasInsufficientData() {
      return (id) => {
        return this.insufficientDataIds.includes(id);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-img {
  background-color: white;
  padding: 4px;
}
.brand-avatar {
  position: relative;
}
.brand-avatar img {
  height: 24px;
}
.brand-avatar-40 img {
  object-fit: contain;
}
.location-brand-name {
  display: flex;
  align-items: center;
  // margin-top: 12px;
  width: 80%;
  .not-enough-data-icon {
    opacity: 0.75;
    height: 14px;
    //margin-top: -3px;
    margin-top: -5px;
    margin-right: 4px;
  }
}
.brandShortenedName {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-items: stretch;
  padding: 5px 8px;
  max-width: 1fr;
  overflow: hidden;
  cursor: pointer;
  p {
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
}
// .brandShortenedName:nth-of-type(1){
//   margin-left: 0px;
// }
.brandShortenedName:hover {
  background-color: rgba(0, 0, 0, 0.03);
  p {
    color: black;
  }
}
.brand-name {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #95aac9;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.circle {
  width: 10px;
  height: 10px;
  background: #2c7be5;
  border-radius: 13px;
  margin-left: 10px;
}
.circleN {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  .M {
    background: #b1c2d9;
  }
}
.circleM {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  background: #b1c2d9;
}
.text {
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 150px;
}
.hidden {
  display: none;
}
.hover {
  cursor: pointer;
}
.disabled {
  background-color: #EDF2F9;
  opacity:0.4;
}

@for $i from 1 through length($location-colors) {
  $color: nth($location-colors, $i);
  .brandShortenedName:nth-child(#{$i}) {
    .circleN {
      background: $color;
    }
  }
}

@media only screen and (max-width: 1275px) {
  .location-brand-name {
    max-width: 70%;
  }
}

@media only screen and (min-width: 2030px) {
  .location-brand-name {
    min-width: 90%;
  }
}
</style>