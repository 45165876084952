<template>
  <div class="polygon-chooser-container">
  <div>
    <!-- <h2>Choose Places to Export Data</h2> -->
    <div class="d-flex polygon-titles">
      <h4 
        @click="onSelect('select')"
        :class="{ selected: isSelected('select') }"
      >
        {{ allowMultiple ? 'Select Places' : 'Select Place' }}
      </h4>
      <h4 
        v-if="showInputMethod('geojson')"
        @click="onSelect('geojson')"
        :class="{ selected: isSelected('geojson') }"
      >
        GeoJSON/KML/ESRI
      </h4>
      <h4 
        v-if="showInputMethod('geojson')"
        @click="onSelect('draw')"
        :class="{ selected: isSelected('draw') }"
      >
        Draw Place
      </h4>
    </div>
    <div class="choice-options">
      <div 
        id="select-polygon"
        :class="{ 'inactive-component': !isSelected('select') }"
        v-if="isSelected('select')"
      >
        <!-- Select Polygon Component -->
        <PolygonLibrary v-if="getPolygonLibraryType() == 'vista'" :allowMultiple="allowMultiple" />

        <EvePolygonLibrary v-if="getPolygonLibraryType() == 'eve'" :allowMultiple="allowMultiple" />
      </div>
      <div 
        id="geojson-polygon"
        :class="{ 'inactive-component': !isSelected('geojson') }"
        v-if="isSelected('geojson')"
      >
        <GeoJSON />
      </div>
      <div 
        id="draw-polygon"
        :class="{ 'inactive-component': !isSelected('draw') }"
      >
      <!-- Draw Polygon Component -->
        <DrawPolygon ref="DrawPolygon" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import GeoJSON from "./GeoJSON";
import DrawPolygon from "./DrawPolygon";
import PolygonLibrary from "./PolygonLibrary";
import EvePolygonLibrary from "./EvePolygonLibrary";

import { syncState } from '../../utils/syncState.js';

export default {
  props: ['allowMultiple'],
  components: {
    GeoJSON,
    DrawPolygon,
    PolygonLibrary,
    EvePolygonLibrary
  },
  data() {
    return {
    }
  },
  computed: {
    ...syncState({
      selectedPolygons: 'state.jobRequest.selectedPolygons|setSelectedPolygons',
      excludedPolygons: 'state.jobRequest.excludedPolygons|setExcludedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams|setSelectAllParams',
      polygonInputOptions: 'state.jobRequest.polygonInputOptions|setPolygonInputOptions',
      uploadInput: 'state.jobRequest.uploadInput|setUploadInput',
      selected: 'state.jobRequest.selectedLocationMethod|setLocationSelectMethod',
      drawnPolygonGeoJSON: 'state.jobRequest.drawnPolygonGeoJSON|setDrawnPolygonGeoJSON',
      polygonString: 'state.jobRequest.polygonString|setPolygonString',
      attachedFile: 'state.jobRequest.attachedFile|setAttachedFile',
    }, { context: this, defaultStore: '$sessionStore' })
  },
  beforeMount() {
    if (this.$route.query.clone !== undefined) {
      this.selected = 'geojson';
      return;
    }

    if (this.$route.query.draw !== undefined && this.selectedPolygons.length !== 1) {
      this.selected = 'draw';
    }

    // Bandaid fix for PIN-1740
    // For some reason resetJobRequest is not resetting the form, possibly a race condition
    if (this.uploadInput.json !== '' || this.uploadInput.kml !== '') {
      this.uploadInput.json = '';
      this.uploadInput.kml = '';
    }
  },
  methods: {
    clearPolygonSelections() {
      this.selectedPolygons = [];
      this.excludedPolygons = [];
      this.selectAllParams = undefined;
    },
    clearUploadSelections() {
      this.uploadInput = {
        kml: '',
        json: '',
        file: '',
        esriField: '',
        selection: 'GEOJSON'
      };
      this.polygonString = '';
      this.attachedFile = undefined;
    },
    clearDrawSelections() {
      this.drawnPolygonGeoJSON = undefined;
    },
    isSelected(selection) {
      return selection === this.selected;
    },
    onSelect(current) {
      // if (this.$route.query.id !== undefined) return;
      this.selected = current;
      if (current !== 'select') {
        // Clear the fields
        this.clearPolygonSelections();
      }
      if (current !== 'geojson') {
        this.clearUploadSelections();
        if (this.$route.query.clone) {
          setTimeout(() => {
            this.$router.replace({ query: {} });
          }, 0);
        }
      }
      if (current === 'draw') {
        this.$refs.DrawPolygon.resize();
        if (this.$route.query.draw) {
          setTimeout(() => {
            this.$router.replace({ query: {}});
          }, 0);
        }
      }
      this.clearDrawSelections();
    },
    getPolygonLibraryType() {
      if (this.$route.path.indexOf('/visits-report/') !== -1) {
        return 'eve';
      }
      return 'vista';
    },
    showInputMethod(input_method) {
      //console.log(this.$route);
      if (this.$route.path.indexOf('/visits-report/') !== -1) {
        if (input_method == "select") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
.polygon-chooser-container {
  width:100%; 
}
.polygon-titles {
  h4 {
    color: #858EA7;
    padding: 10px 20px;
    border-bottom: 1px solid #E3EBF6;
    border-top: 1px solid transparent;
  }
  .selected {
    color: #4e90e0;
    border-bottom: 1px solid #4e90e0;
  }
  h4:hover {
    cursor: pointer;
  }
}

.choice-options {
  border: 1px solid #E3EBF6;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
}

.inactive-component {
  display: none;
}
</style>
