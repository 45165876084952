import { render, staticRenderFns } from "./CreatePage.vue?vue&type=template&id=384a760c&scoped=true&"
import script from "./CreatePage.vue?vue&type=script&lang=js&"
export * from "./CreatePage.vue?vue&type=script&lang=js&"
import style0 from "./CreatePage.vue?vue&type=style&index=0&id=384a760c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384a760c",
  null
  
)

export default component.exports