<template>
  <div :id="id" class="card chart-card">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-skeleton-wrapper :loadingA="isLoadingToLocation">
          <template #loadingA>
            <b-skeleton height="50px" width="50px" class="mr-3"></b-skeleton>
          </template>
          <div class="icon">
            <slot name="icon"></slot>
          </div>
        </b-skeleton-wrapper>
        <b-skeleton-wrapper :loadingB="isLoadingToLocation">
          <template #loadingB>
            <b-skeleton width="100px"></b-skeleton>
            <b-skeleton width="400px"></b-skeleton>
          </template>
          <div style="margin-left: 5px">
            <slot name="title"></slot>
          </div>
        </b-skeleton-wrapper>
      </div>
      <b-skeleton-wrapper :loadingC="isLoadingToLocation || isLoading">
        <template #loadingC></template>
      </b-skeleton-wrapper>
      <slot name="dropdown"></slot>
    </div>

    <b-skeleton-wrapper :loadingD="isLoadingToLocation">
      <span class="line"></span>
      <template #loadingD>
        <div
          class="d-flex align-items-center justify-content-between chart-nav"
        >
          <div class="d-flex">
            <b-skeleton type="avatar" width="26px" height="26px"></b-skeleton>
            <b-skeleton
              height="24px"
              width="220px"
              style="margin-left: 16px"
            ></b-skeleton>
          </div>
          <div class="d-flex">
            <b-skeleton height="24px" width="60px" class="mr-3"></b-skeleton>
            <b-skeleton height="24px" width="60px"></b-skeleton>
          </div>
        </div>
      </template>
      <div class="d-flex align-items-center justify-content-between chart-nav">
        <slot name="name-card"></slot>
      </div>
    </b-skeleton-wrapper>

    <b-skeleton-wrapper :loading="isLoadingToLocation || isLoading">
      <template #loading>
        <b-skeleton
          type="image"
          style="min-height: 400px; border-radius: 6px"
        ></b-skeleton>
      </template>

      <div class="charts-container">
        <slot name="charts-container"></slot>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    isLoadingToLocation: Boolean,
    isLoading: Boolean
  },
};
</script>
<style lang="scss" scoped>
@import "./ReportChartStyles.scss";
</style>