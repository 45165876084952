<template>
  <div>
    <div class="section">
      <CreateHeader :useRouteMeta="true"></CreateHeader>

    </div>
    <div class="section">
      <div class="report-name">
        <p class="required">*Required</p>
        <h2 class="input-label">Name</h2>
        <b-form-input v-model="rawName" class="job-name-input" placeholder="Name is required"
          :state="isNameStateValid"></b-form-input>
        <p class="subtext">
          Limit 75 characters. Invalid text: |\:();{}*?/'"
        </p>
      </div>
    </div>
    <div class="section">
    </div>
  </div>
</template>

<script>
import CreateHeader from './CreateHeader.vue';
import CardUtil from '../../../../services/cards/cardUtil.js';
import { syncState } from '../../../../utils/syncState.js';
import LargeJobWarning from '@/components/dataExplorer/shared/LargeJobWarning.vue';

export default {
  mixins: [CardUtil],
  components: { CreateHeader, LargeJobWarning },
  data() {
    return {
      rawName: this.$sessionStore.state.jobRequest.reportOutputOptions.reportName,
    };
  },
  created() {
  },
  beforeMount() {
    if (!this.$route.query.clone) {
      this.rawName = '';
    }
  },
  watch: {
    rawName() {
      if (this.isNameStateValid) this.jobName = this.rawName;
      else this.jobName = '';
    }
  },
  computed: {
    ...syncState({
      jobName: 'state.jobRequest.reportOutputOptions.reportName|setReportName'
    }, { context: this, defaultStore: '$sessionStore' }),
    isNameStateValid() {
      if (this.rawName === '') return false;
      var format = /[<>|:()&;{}*'"\/?]/;
      if (format.test(this.rawName)) {
        return false;
      } else if (this.rawName.length > 200) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.report-type,
.report-name {
  width: 25%;
}
</style>