import { GetMixin } from "pinnacle-lib";
import JobService from "../dataExplorer/jobService";

export default {
  mixins: [GetMixin, JobService],
  data() {
    return {
      templates: []
    };
  },
  methods: {
    /**
     * Fetches templates from the cache then lazily updates.
     * Modifies the this.templates field.
     */
    async provisionTemplates() {
      this.templates = this.getCachedTemplates();
      let lastUpdated = localStorage.getItem("_lu_vrt");
      if (lastUpdated === null || this.templates.length === 0) {
        const templates = await this.getTemplates();
        this.templates = templates;
        localStorage.setItem("_lu_vrt", Math.floor(new Date().getTime() / 1000).toString());
        localStorage.setItem("viz-report-templates", JSON.stringify(templates));
        return;
      }
      lastUpdated = Number.parseInt(lastUpdated);
      if (Math.floor(new Date().getTime() / 1000) - lastUpdated > 60 * 60 * 24) {
        const templates = await this.getTemplates();
        this.templates = templates;
        localStorage.setItem("_lu_vrt", Math.floor(new Date().getTime() / 1000));
        localStorage.setItem("viz-report-templates", JSON.stringify(templates));
        return;
      }
      // Don't wait for the templates to reload
      this.getTemplates().then(templates => {
        this.templates = templates;
      });
    },
    /**
     * Fetches the cached templates.
     * @returns {Array} Either an array of templates or an empty array.
     */
    getCachedTemplates() {
      const cachedTemplates = localStorage.getItem("viz-report-templates");
      if (cachedTemplates !== "undefined") return JSON.parse(cachedTemplates);
      return [];
    },
    /**
         * Requests the Pinnacle API for chart data.
         * @param {*} endpoint The target endpoint.
         * @param {boolean} noCache Will set no cache headers if true.
         * @returns Returns the axios response.
         */
    async getCardData(endpoint, noCache=false) {
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        if (noCache) {
          headers.headers['Cache-Control'] = 'no-cache';
          headers.headers['Pragma'] = 'no-cache';
          headers.headers['Expires'] = '0';
        }
        const data = await this.$http.get(endpoint, headers);
        return data;
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errors.length) {
          this.err = err.response.data.errors[0].message;
        }
        console.log({ err });
      }
    },
    async getCardReports(cardId) {
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        let url = `${process.env.VUE_APP_PINNACLE_API}/v2/get-card-reports/${cardId}`;
        const data = await this.$http.get(url, headers);
        return data;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
          this.err = err.response.data.errors[0].message;
        }
        console.log({ err });
      }      
    },
    async getEmbedHash(cardId) {
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        let url = `${process.env.VUE_APP_PINNACLE_API}/v2/get-embed-hash/${cardId}`;
        const data = await this.$http.get(url, headers);
        return data;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
          this.err = err.response.data.errors[0].message;
        }
        console.log({ err });
      }
    },
    /**
     * Fetches the templates and returns them. Returns [] if error.
     * @returns {Array} Returns the templates.
     */
    async getTemplates() {
      try {
        const data = await this.getFromURL(`${process.env.VUE_APP_PINNACLE_API}/v2/get-templates`);
        return data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    async postCardData(endpoint, body) {
        try {
          if (this.tokenNeedsRefresh()) await this.refreshToken();
          const headers = this.getHeaders();
          //console.log(body)
          const data = await this.$http.post(endpoint,body, headers);
          return data;
        } catch (err) {
          if (err.response && err.response.data && err.response.data.errors.length) {
            this.err = err.response.data.errors[0].message;
          }
          console.log({ err });
        }
      },
    async requestReport() {
      const jobRequestCopy = Object.assign({}, this.jobRequest);
      this.parsePolygonSelection(jobRequestCopy);
      const { reportName } = jobRequestCopy.reportOutputOptions;
      const { name, id, project_id } = jobRequestCopy.template;
      const { startDateTime, endDateTime, polygonInputOptions, allowLargePolygons } = jobRequestCopy;
      // calculate number of polygons
      let parsedGeojson = await this.parseGeojson();
      let features = this.collectFeaturesFromGeoJSON(parsedGeojson);
      let numPolygons = features.length;
      const params = {
        name: reportName,
        template_name: name,
        template_id: id,
        template_project_id: project_id,
        startDateTime,
        endDateTime,
        polygonInputOptions,
        allowLargePolygons,
        numPolygons
      };

      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const config = this.getHeaders();
        const api_path = polygonInputOptions.polygonFormat == 'ESRI_SHAPEFILE_ZIP' ? 'createJobWithFile' : 'createJob';
        let res;
        if (jobRequestCopy.attachedFile !== undefined) {
          const formData = new FormData();
          formData.append("polygonFile", jobRequestCopy.attachedFile);
          formData.append("jsonRequest", JSON.stringify(params));
          config.headers['Content-Type'] = 'multipart/form-data';
          res = await this.$http.post(`/v2/create-insight/${this.getEnvironmentKey()}/${api_path}`, formData, config);
        } else {
          res = await this.$http.post(`/v2/create-insight/${this.getEnvironmentKey()}/${api_path}`, params, config);
        }
        return res.data;
      } catch (raw) {
        console.log(raw);
        if ('error' in raw) return {succeeded: false, error: raw.error};
        if (raw && raw.response && raw.response.data && raw.response.data.error) {
          return {succeeded: false, error: raw.response.data.error}  
        }
        return {succeeded: false, error: raw}
      }
    },
    async requestVisitsReport() {
      const jobRequestCopy = Object.assign({}, this.jobRequest);
      //console.log(jobRequestCopy);
      //console.log(jobRequestCopy.selectedPolygons);
      let polygonIds = [];
      if (jobRequestCopy.selectedPolygons !== undefined && jobRequestCopy.selectedPolygons.length > 0) {
        polygonIds = jobRequestCopy.selectedPolygons.map(p => p.id);
      }
      //console.log(polygonIds);
      //this.parsePolygonSelection(jobRequestCopy);
      //console.log('reportOutputOptions', jobRequestCopy.reportOutputOptions);
      const { reportName } = jobRequestCopy.reportOutputOptions;
      //const { name, id, project_id } = jobRequestCopy.template;
      const { startDateTime, endDateTime, polygonInputOptions } = jobRequestCopy;
      // calculate number of polygons
      //let parsedGeojson = await this.parseGeojson();
      //let features = this.collectFeaturesFromGeoJSON(parsedGeojson);
      //let numPolygons = features.length;
      const params = {
        name: reportName,
        startDateTime,
        endDateTime,
        polygonInputOptions,
        polygonIds
      };

      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const config = this.getHeaders();
        let res;
        res = await this.$http.post(`/v2/create-daily-visits-report`, params, config);
        return res.data;
      } catch (raw) {
        //console.log(raw);
        if ('error' in raw) return {succeeded: false, error: raw.error};
        if (raw && raw.response && raw.response.data && raw.response.data.error) {
          return {succeeded: false, error: raw.response.data.error}  
        }
        return {succeeded: false, error: raw}
      }
    }
  }
}