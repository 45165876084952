<template>
  <div class="login-body">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="12" sm="12" md="12" lg="6" xl="6">
          <div class="left-card-login">
            <router-link
              v-bind:class="{ notVisibleBtn: isActive }"
              to="/login"
              class="back-btn-forgot-pw"
            >
              <img :src="require('pinnacle-lib/assets/icon/back-btn.svg')" alt="back" />
            </router-link>
            <div :class="wrapperClass">
              <img
                :src="require('pinnacle-lib/assets/icon/pinnacle-logo.svg')"
                style="height: 76px; width: 96px"
                class="forgot-pw-brand-img center-block mb-3"
                alt="logo"
              />
              <h1 class="display-4 text-center mb-3">{{ formName }}</h1>
              <p class="text-center text-muted">Pinnacle by {{ Constants.company_name }}</p>
              <div class="regText" v-if="formName === 'Register'">
                <p style="width: 375px">
                  Please enter your invite code and then create a new secure
                  password to sign in to your Pinnacle account.
                </p>

                <p>
                  You will use your email and your new password to sign in in
                  the future.
                </p>
              </div>
              <b-form @submit="onSubmit">
                <div role="group" class="mb-4">
                  <label for="email" v-if="formName !== 'Register'"
                    >Email Address</label
                  >
                  <b-form-input
                    id="email"
                    type="email"
                    v-model="email"
                    :state="emailState"
                    placeholder="name@address.com"
                    v-if="formName !== 'Register'"
                  ></b-form-input>
                  <label for="email" v-if="formName === 'Register'"
                    >Invite Code</label
                  >
                  <b-form-input
                    id="email"
                    v-model="emailToken"
                    :state="tokeState"
                    placeholder="Invite code"
                    v-if="formName === 'Register'"
                  ></b-form-input>
                  <!-- This will only be shown if the preceding input has an invalid state -->
                  <!-- <div class="error grey" v-if="emailState || email === ''">
                    <span v-if="formName === 'Register' && email === ''">
                      Please enter your work email address
                    </span>
                  </div> -->
                  <b-form-invalid-feedback id="input-live-feedback">
                    The email must have '@email.com'
                  </b-form-invalid-feedback>
                </div>
                <!-- PASSWORD LAYOUTS -->
                <template v-if="formName === 'Sign in'">
                  <div role="group" class="mb-4">
                    <div class="row">
                      <label for="password" class="col">Password</label>
                      <div class="col-auto">
                        <router-link
                          to="/forgot-password"
                          class="form-text small text-muted"
                          >Forgot Password</router-link
                        >
                      </div>
                    </div>
                    <div class="toggle-password">
                      <b-form-input
                        id="password"
                        class="password-input"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        :state="lpasswordState"
                        placeholder="Enter your password"
                      >
                      </b-form-input>
                      <img
                        v-if="showPassword"
                        :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                        class="eye-icon"
                        alt="eye"
                        style="top: 33%"
                        @click="showPassword = !showPassword"
                      />
                      <img
                        v-else
                        :src="require('pinnacle-lib/assets/icon/eye.svg')"
                        class="eye-icon"
                        alt="eye"
                        style="top: 33%"
                        @click="showPassword = !showPassword"
                      />
                      <div class="error">
                        <span class="text-danger">{{ errData }}</span>
                      </div>
                    </div>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-feedback">
                      The password must have at least 8 characters
                    </b-form-invalid-feedback>
                  </div>
                </template>
                <template v-if="formName === 'Register'">
                  <div role="group" class="mb-4">
                    <div class="row">
                      <label for="password" class="col">Password</label>
                    </div>
                    <div class="toggle-password">
                      <b-form-input
                        id="password"
                        class="password-input"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        :state="passwordState"
                        placeholder="Enter your password"
                      >
                      </b-form-input>
                      <img
                        v-if="showPassword"
                        :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                        class="eye-icon"
                        alt="eye"
                        @click="showPassword = !showPassword"
                      />
                      <img
                        v-else
                        :src="require('pinnacle-lib/assets/icon/eye.svg')"
                        class="eye-icon"
                        alt="eye"
                        @click="showPassword = !showPassword"
                      />
                    </div>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <!-- <div class="error grey" >Your password must:</div> -->
                    <div class="reqCont">
                      <div>
                        <p
                          class="req"
                          v-bind:class="
                            lpasswordState === null
                              ? 'grey'
                              : lpasswordState
                              ? 'pass'
                              : 'grey'
                          "
                        >
                          <img
                            class="check"
                            :src="lpasswordState ? check : x"
                            alt="check"
                          />Minimum of 8 characters
                        </p>
                        <p
                          class="req"
                          v-bind:class="
                            spec === null ? 'grey' : spec ? 'pass' : 'grey'
                          "
                        >
                          <img class="check" :src="spec ? check : x" alt="check" />At least
                          one special character (!@#?])
                        </p>
                        <p
                          class="req"
                          v-bind:class="
                            num === null ? 'grey' : num ? 'pass' : 'grey'
                          "
                        >
                          <img class="check" :src="num ? check : x" alt="check" />At least
                          one number
                        </p>
                      </div>
                      <div>
                        <p
                          class="req"
                          v-bind:class="
                            upper === null ? 'grey' : upper ? 'pass' : 'grey'
                          "
                        >
                          <img class="check" :src="upper ? check : x" alt="check" />At least
                          one uppercase character
                        </p>
                        <p
                          class="req"
                          v-bind:class="
                            lower === null ? 'grey' : lower ? 'pass' : 'grey'
                          "
                        >
                          <img class="check" :src="lower ? check : x" alt="check" />At least
                          one lowercase character
                        </p>
                      </div>
                    </div>
                  </div>
                  <div role="group" class="mb-4">
                    <div class="row">
                      <label for="cpassword" class="col" @click="log()"
                        >Confirm new password</label
                      >
                    </div>
                    <div class="toggle-password">
                      <b-form-input
                        id="password"
                        v-model="cpassword"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :state="cpasswordState"
                        placeholder="Enter your password"
                      >
                      </b-form-input>
                      <img
                        v-if="showConfirmPassword"
                        :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                        alt=""
                        @click="showConfirmPassword = !showConfirmPassword"
                      />
                      <img
                        v-else
                        :src="require('pinnacle-lib/assets/icon/eye.svg')"
                        alt=""
                        @click="showConfirmPassword = !showConfirmPassword"
                      />
                    </div>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <div
                      class="error"
                      v-if="cpasswordState || cpassword === ''"
                    >
                      <span class="text-danger">{{ errData }}</span>
                    </div>
                    <b-form-invalid-feedback
                      :state="cpasswordState"
                      id="input-live-feedback"
                    >
                      Your password does not match.
                    </b-form-invalid-feedback>
                  </div>
                </template>
                <!-- BUTTON LAYOUTS -->
                <template v-if="formName === 'Sign in'">
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    class="mb-3"
                    type="submit"
                    :disabled="!(emailState && lpasswordState) || submitting"
                  >
                    <span v-if="submitting"
                      ><b-spinner
                        small
                        label="Spinning"
                        variant="light"
                      ></b-spinner
                    ></span>
                    <span v-else>Sign in</span>
                  </b-button>
                </template>
                <template v-else-if="formName === 'Register'">
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    class="mb-3"
                    :disabled="
                      !(
                        emailAdd &&
                        password &&
                        passwordState &&
                        cpassword &&
                        cpasswordState &&
                        token
                      ) || submitting
                    "
                    type="submit"
                  >
                    <span v-if="submitting"
                      ><b-spinner
                        small
                        label="Spinning"
                        variant="light"
                      ></b-spinner
                    ></span>
                    <span v-else>Submit</span>
                  </b-button>
                </template>
                <template v-else-if="formName === 'Forgot Password'">
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    class="mb-3"
                    :disabled="!emailState || submitting"
                    type="submit"
                  >
                    <span v-if="submitting"
                      ><b-spinner
                        small
                        label="Spinning"
                        variant="light"
                      ></b-spinner
                    ></span>
                    <span v-else>Send verification code</span>
                  </b-button>
                </template>
                <!-- MISC LAYOUTS  -->
                <template v-if="formName === 'Sign in'">
                  <!--not a part of the 1st build --->

                  <!-- <div class="text-center">
                    <small class="text-muted text-center">
                      Don't have an account yet?
                      <router-link to="/register">Register</router-link>
                    </small>
                  </div> -->
                </template>
                <template v-if="formName === 'Register'">
                  <!-- <div class="text-center">
                    <small class="text-muted text-center">
                      Already have an account?
                      <router-link to="/login">Sign in</router-link>
                    </small>
                  </div> -->
                </template>
              </b-form>
            </div>
            <!-- MODAL LAYOUTS -->
            <template v-if="formName === 'Forgot Password'">
              <b-modal id="sent-code-modal" hide-footer>
                <div
                  class="
                    p-4
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    reset-password-modal
                  "
                >
                  <MailIcon />
                  <h2 class="mt-4">Your verification code has been sent!</h2>
                  <p class="text-center">
                    Please complete the following information to change your
                    password.
                  </p>
                  <b-form @submit="onResetPasswordSubmit">
                    <div role="group" class="mb-4">
                      <div class="row">
                        <label for="password" class="col">New Password</label>
                      </div>
                      <div class="toggle-password">
                        <b-form-input
                          id="password"
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          :state="passwordState"
                          placeholder="Enter your password"
                          class="password-input"
                        >
                        </b-form-input>
                        <img
                          v-if="showPassword"
                          :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                          class="eye-icon"
                          alt=""
                          @click="showPassword = !showPassword"
                        />
                        <img
                          v-else
                          :src="require('pinnacle-lib/assets/icon/eye.svg')"
                          class="eye-icon"
                          alt=""
                          @click="showPassword = !showPassword"
                        />
                      </div>
                      <!-- This will only be shown if the preceding input has an invalid state -->
                      <!-- <div class="error grey" >Your password must:</div> -->
                      <div class="reqCont">
                        <div>
                          <p
                            class="req"
                            v-bind:class="
                              lpasswordState === null
                                ? 'grey'
                                : lpasswordState
                                ? 'pass'
                                : 'grey'
                            "
                          >
                            <img
                              class="check"
                              :src="lpasswordState ? check : x"
                              alt="check"
                            />Minimum of 8 characters
                          </p>
                          <p
                            class="req"
                            v-bind:class="
                              spec === null ? 'grey' : spec ? 'pass' : 'grey'
                            "
                          >
                            <img class="check" :src="spec ? check : x" alt="check" />At
                            least one special character (!@#?])
                          </p>
                          <p
                            class="req"
                            v-bind:class="
                              num === null ? 'grey' : num ? 'pass' : 'grey'
                            "
                          >
                            <img class="check" :src="num ? check : x" alt="check" />At least
                            one number
                          </p>
                        </div>
                        <div>
                          <p
                            class="req"
                            v-bind:class="
                              upper === null ? 'grey' : upper ? 'pass' : 'grey'
                            "
                          >
                            <img class="check" :src="upper ? check : x" alt="check" />At
                            least one uppercase character
                          </p>
                          <p
                            class="req"
                            v-bind:class="
                              lower === null ? 'grey' : lower ? 'pass' : 'grey'
                            "
                          >
                            <img class="check" :src="lower ? check : x" alt="check" />At
                            least one lowercase character
                          </p>
                        </div>
                      </div>
                    </div>
                    <div role="group" class="mb-4">
                      <div class="row">
                        <label for="cpassword" class="col"
                          >Confirm new password</label
                        >
                      </div>
                      <div class="toggle-password">
                        <b-form-input
                          id="password"
                          v-model="cpassword"
                          :type="showConfirmPassword ? 'text' : 'password'"
                          :state="cpasswordState"
                          placeholder="Enter your password"
                          class="password-input"
                        >
                        </b-form-input>
                        <img
                          v-if="showConfirmPassword"
                          class="eye-icon"
                          :src="require('pinnacle-lib/assets/icon/eye-focused.svg')"
                          alt=""
                          @click="showConfirmPassword = !showConfirmPassword"
                        />
                        <img
                          v-else
                          :src="require('pinnacle-lib/assets/icon/eye.svg')"
                          class="eye-icon"
                          alt=""
                          @click="showConfirmPassword = !showConfirmPassword"
                        />
                      </div>
                      <!-- This will only be shown if the preceding input has an invalid state -->
                      <div
                        class="error"
                        v-if="cpasswordState || cpassword === ''"
                      ></div>
                      <b-form-invalid-feedback
                        :state="cpasswordState"
                        id="input-live-feedback"
                      >
                        Your password does not match.
                      </b-form-invalid-feedback>
                    </div>

                    <div role="group" class="mb-4">
                      <label for="code">
                        Please enter the verification code sent to
                        <span class="text-primary">{{
                          email || "hey@example.com"
                        }}</span>
                        to continue.</label
                      >
                      <b-form-input
                        id="code"
                        type="text"
                        v-model="code"
                        :state="codeState"
                        placeholder=""
                      ></b-form-input>
                      <div class="error">
                        <span class="text-danger">{{ errResetData }}</span>
                      </div>
                    </div>
                    <b-button
                      block
                      variant="primary"
                      size="lg"
                      class="mb-3"
                      type="submit"
                      :disabled="
                        !(cpasswordState && passwordState && codeState) ||
                        submittingConfirm
                      "
                    >
                      <span v-if="submittingConfirm"
                        ><b-spinner
                          small
                          label="Spinning"
                          variant="light"
                        ></b-spinner
                      ></span>
                      <span v-else>Reset password</span>
                    </b-button>
                  </b-form>
                </div>
              </b-modal>
            </template>
            <b-modal id="success-reset-modal" hide-footer hide-header>
              <div
                class="
                  p-4
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <PasswordChangedIcon />
                <h2 class="mt-4">Your password has been changed!</h2>
                <p class="text-center">
                  Use the button below to return to the sign in screen and sign
                  in as normal with your new password.
                </p>
                <b-button
                  block
                  variant="primary"
                  size="lg"
                  class="mb-3"
                  @click="$router.push('/login')"
                  >Proceed to sign in</b-button
                >
              </div>
            </b-modal>
          </div>
        </b-col>
        <b-col cols="12" sm="12" lg="7" md="12" xl="6">
          <div
            v-bind:class="{
              bigRight: formName === 'Register',
              smallRight: formName !== 'Register',
            }"
          >
            <img :src="require('pinnacle-lib/assets/img/login-img.png')" alt="Right Image" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MailIcon from "../partials/MailIcon.vue";
import PasswordChangedIcon from "../partials/PasswordChangedIcon.vue";
import { saveUser } from "pinnacle-lib/utils/user";
import { ConstantsService } from "@/services/constants.js";

export default {
  mixins: [ConstantsService],
  props: {
    formName: {
      type: String,
    },
    emailAdd: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {
      err: "",
      errReset: "",
      email: "",
      emailToken: "",
      password: "",
      cpassword: "",
      code: "",
      remember: false,
      submitting: false,
      submittingConfirm: false,
      showPassword: false,
      showConfirmPassword: false,
      isActive: true,
      check: require('pinnacle-lib/assets/img/check_mark.svg'),
      x: require('pinnacle-lib/assets/img/x_mark.svg'),
    };
  },
  components: {
    MailIcon,
    PasswordChangedIcon,
  },
  beforeCreate() {
    if (localStorage.getItem("isAuthenticated")) {
      this.$router.push("/");
    }
  },
  mounted() {
    if (this.$route.fullPath == "/forgot-password") {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  },
  computed: {
    wrapperClass() {
      if (this.formName !== "Register") {
        return "login-wrapper";
      } else {
        return "reg-wrapper";
      }
    },
    emailState() {
      let reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return this.email === "" ? null : reg.test(this.email) ? true : false;
    },
    lpasswordState() {
      return this.password === ""
        ? null
        : this.password.length > 7
        ? true
        : false;
    },
    tokeState() {
      return null;
    },
    passwordState() {
      let reg = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
      return this.password === ""
        ? null
        : this.password.length > 7
        ? reg.test(this.password)
        : false
        ? true
        : false;
    },
    cpasswordState() {
      return this.cpassword === ""
        ? null
        : this.cpassword === this.password
        ? true
        : false;
    },
    codeState() {
      return this.code === "" ? null : !!this.code;
    },
    errData() {
      // return this.err;
      if (this.err === "Incorrect username or password.") {
        return "Incorrect email or password.";
      } else {
        return this.err;
      }
    },
    errResetData() {
      return this.errReset;
    },
    upper() {
      let reg = /(?=.*[A-Z])/;
      return this.password === ""
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
    lower() {
      let reg = /(?=.*[a-z])/;
      return this.password === ""
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
    num() {
      let reg = /(?=.*[0-9])/;
      return this.password === ""
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
    spec() {
      let reg = /(?=.*\W)/;
      return this.password === ""
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
  },
  methods: {
    log(x) {
      console.log(this.passwordState);
    },
    onSubmit(e) {
      e.preventDefault();

      if (this.formName === "Sign in") {
        this.login();
      } else if (this.formName === "Register") {
        this.register();
      } else if (this.formName === "Forgot Password") {
        this.reset();
      }
    },
    onResetPasswordSubmit(e) {
      e.preventDefault();
      this.confirmReset();
    },
    async login(args) {
      const { email, password } = this;
      this.submitting = true;
      this.err = "";
      try {
        const res = await this.$http.post("/v1/login", { email, password });
        if (res.data) {                    
          saveUser(res.data);

          if (args?.withSuccessRegister) {
            this.$store.commit("setSuccessRegister", email);
          }
          const { permissions  } = res.data;
          if (permissions) {
            this.$persistingStore.commit("setPermissions", permissions);
          }
          if (typeof analytics !== "undefined") {
            analytics.identify(res.data.id, {
              company_id: res.data.company.id,
              id: res.data.id,
              email: email,
            });
          }
          
          await this.$nextTick();
          this.$router.push("/");
        }
      } catch (err) {
        console.log(err);
        if (!err.response && !err.response.data && !err.response.data.errors[0]) {
          this.err = "Password miss-match, please use forgot password to reset";
        } else {
          const error = err.response.data.errors[0].message;
          this.err = error;
        }
      }
      this.submitting = false;
    },
    async register() {
      const email = this.emailAdd;
      const password = this.password;
      const inviteToken = this.token;
      const invitePassword = this.emailToken;

      this.submitting = true;
      this.err = "";
      try {
        const res = await this.$http.post("/v1/invite", {
          email,
          password,
          invitePassword,
          inviteToken,
        });
        if (res.data) {
          saveUser(res.data);

          analytics.identify(res.data.id, {
            company_id: res.data.company.id,
            id: res.data.id,
            email: email,
          });
          this.$router.push(`/explore`);
        }
      } catch (err) {
        const error = err.response.data.errors[0].message;
        this.err = error;
      }
      this.submitting = false;
    },
    async reset() {
      const { email } = this;
      this.submitting = true;
      this.err = "";
      try {
        const res = await this.$http.post("/v1/reset-password", {
          email,
        });
        if (res.data) {
          this.err = "";
          this.password = "";
          this.cpassword = "";
          this.code = "";
          this.$bvModal.show("sent-code-modal");
        }
      } catch (err) {
        const error = err.response.data.errors[0].message;
        this.err = error;
      }
      this.submitting = false;
    },
    async confirmReset() {
      const { email, password } = this;
      this.submittingConfirm = true;
      this.errReset = "";
      const code = this.code.trim();
      try {
        const res = await this.$http.post("/v1/reset-password/confirm", {
          code,
          email,
          password,
        });
        if (res.data) {
          this.errReset = "";
          this.$bvModal.hide("sent-code-modal");
          this.$bvModal.show("success-reset-modal");
        }
      } catch (err) {
        const error = err.response.data.errors[0].message;
        this.errReset = error;
      }
      this.submittingConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #success-reset-modal___BV_modal_content_ {
  height: 27em;
  margin-top: 40%;
  width: 42em;
  font-family: Cerebri Sans;
  h2 {
    font-weight: 500;
  }
  .text-center {
    color: #6e84a3;
    font-weight: 400;
  }
  .btn-block {
    margin-top: 30px;
  }
}

.notVisibleBtn {
  display: none;
}

::v-deep .modal-dialog {
  .modal-content {
    height: 60em;
  }
}

.send-code-modal {
  padding: 1rem 4rem 1rem 4rem;
}

::v-deep #sent-code-modal {
  .modal-body {
    padding-top: 0px !important;
  }
  .reset-password-modal {
    padding-top: 0px !important;
  }
  .modal-header {
    border: none;
    margin-top: 8px;
  }
}

.back-btn-forgot-pw {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 5%;
  left: 5%;
}

.forgot-pw-brand-img {
  width: 48px;
  height: 38px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-control {
  border-color: #d2ddec;
}

button {
  margin-top: 3em;
  height: 58px;
}

form {
  margin-top: 38px;
}

::v-deep .btn-primary {
  border-radius: 6px;
}

.toggle-password {
  position: relative;

  img {
    position: absolute;
    right: calc(0.375em + 0.1875rem);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

h1 {
  font-family: "Cerebri Sans Bold";
}

.left-card-login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    // background: #2c7be5;
    background: #ffffff;
    height: 4px;
    width: 100%;
    content: "";
  }

  .login-wrapper {
    width: 450px;
    margin: 0 auto;
    padding: 0px 42px 0px 42px;
    h1 {
      letter-spacing: -10%;
      letter-spacing: -0.03px;
      font-family: "Cerebri Sans";
      font-weight: 500;
    }
  }
  .reg-wrapper {
    width: 624px;
    margin: 0 auto;
    padding: 0px 42px 0px 42px;
    h1 {
      letter-spacing: -10%;
      letter-spacing: -0.03px;
      font-family: "Cerebri Sans";
      font-weight: 500;
    }
  }
}

.bigRight {
  height: 100vh;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media screen and (max-height: 801px) {
    height: 801px;
  }
}

.smallRight {
  height: 100vh;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

b-modal .reset-password-modal {
  padding: 0.8em 3.5em 0.3em 3.5em !important;
}
.error {
  height: 23px;
  font-size: 13px;
  padding-top: 4px;
}
.req {
  font-size: 13px;
  margin-bottom: 0px;
}
.pass {
  color: #2c7be5;
}
.fail {
  color: #e63757;
}
.grey {
  color: #6e84a3;
}
.reqCont {
  width: 33.75rem;
  height: 5rem;
  left: 0px;
  background: #edf2f9;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
.check {
  margin-right: 10px;
  margin-left: 20px;
}
.is-invalid {
  border-color: #2c7be5 !important;
}
.is-valid {
  border-color: #d2ddec !important;
}
.regText {
  font-family: "Cerebri Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  width: 466px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;
  color: #12263f;
}

.password-input.is-valid + .eye-icon, .password-input.is-invalid + .eye-icon {
  right: 32px;
}

.password-input.is-valid {
  background-position: right calc(0.375em + 0.1875rem) center !important;
}

::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>
