<template>
  <div class="topbar-nav">
    <b-modal id="my-modal" centered hide-header hide-footer  no-close-on-esc >
      <Download />
    </b-modal>
    <b-modal id="downloading" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc >
      <div class='downloadModal'>
        <vue-lottie-player
            autoplay
            loop
            mode="normal"
            :animationData="require('pinnacle-lib/assets/lottie/download-lottie.json')"
            width="46px"
            height="46px"
            name="download-lottie"
            v-if="downloading"
          />
        <img v-else :src="require('pinnacle-lib/assets/icon/check-circle.svg')" alt='done' />
      </div>
        <h1 v-if="downloading" class='downloadTitle' >Preparing Download</h1>
        <h1 v-else class='downloadTitle' >Download Complete</h1>
        <p v-if="downloading" class='downloadDesc'>We’re preparing your insights for downloading. Please wait for a moment while the process completes.</p>
        <p v-else class='downloadDesc'>Please download your insights from your computer’s downloads folder.</p>
          <button
            class="primary-buttonB"
            variant="primary"
            v-if="!downloading"
            @click='$bvModal.hide("downloading")'
          >
            Okay
          </button>        

        
    </b-modal>
    <div id="topbar-nav-scroll" :class="{ fixedTopbarNav: isFixed }">
      <nav class="navbar navbar-expand-md navbar-light p-0" id="topbar">
        <div style="height: 54px;" class="container-fluid justify-content-between align-items-center">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div
                class="nav-link"
                :class="{'nav-item-active': pathSplit[3] === 'charts'}"
                @click="$router.push(preview ? { name: 'preview-charts' } : { name: 'study-charts' })"
              >
                <img
                  :src="
                    pathSplit[3] === 'charts'
                      ? chartSvgSelected
                      : chartSvgDeselected
                  "
                />
                <span>Chart Insights</span>
              </div>
            </li>

            <li class="nav-item">
              <div
                class="nav-link"
                :class="{'nav-item-active': pathSplit[3] === 'map'}"
                @click="$router.push(preview ? { name: 'preview-map' } : { name: 'study-map' })"
              >
                <img
                  :src="
                    pathSplit[3] === 'map'
                      ? mapSvgSelected
                      : mapSvgDeselected
                  "
                />
                <span>Map Insights</span>
              </div>
            </li>
          </ul>
          <div class="topbar-right">
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import DateRangeFiltersCard from "../partials/DateRangeFiltersCard.vue";
// import ShareButton from "../partials/ShareButton.vue";
import { bus } from "@/main";
import Download from "../partials/Download.vue"
import VueLottiePlayer from "vue-lottie-player";
export default {
  components: {
    DateRangeFiltersCard,
    Download,
    VueLottiePlayer
    // ShareButton,
  },
  data() {
    return {
      isFixed: false,
      chartSvgSelected: require("pinnacle-lib/assets/icon/chart-selected.svg"),
      chartSvgDeselected: require("pinnacle-lib/assets/icon/chart-deselected.svg"),
      mapSvgSelected: require("pinnacle-lib/assets/icon/map-selected.svg"),
      mapSvgDeselected: require("pinnacle-lib/assets/icon/map-deselected.svg"),
      studySvg: require("pinnacle-lib/assets/icon/study-icon.svg"),
    };
  },
  props: {
    preview: {
      type: Boolean,
    },
  },
  computed: {
    pathSplit() {
      return this.$route.path.split('/');
    },
    crumbs() {
      return this.$sessionStore.state.crumbs.trail;
    },
    downloading(){
      return this.$sessionStore.state.study.downloading;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    collapsePlacesCard() {
      bus.$emit("collapsePlacesCard");
    },
    closeStudy() {
      const route = { path: `/explore/search` };
      this.$router.push({ path: `/explore/search` });
    },
    // closePreview(){
    //   let placeDetails = this.$sessionStore.state.study.previews[0];
    //   const encodedPlace = btoa(JSON.stringify(placeDetails));
    //   const query = { place: encodedPlace };
    //   if (this.$route.query.edit === "true") query.edit = true;
    //   this.$router.push({
    //     path: "/explore/details",
    //     query,
    //   });
    // }
  },
};
</script>

<style lang="scss" scoped>
.topbar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.navbar-nav {
width: 36%;
}

.navbar {
width: 100%;
display: flex;
}
.topBarDateRange {
  background-color: #f9fafc !important;
  border: none !important;
  box-shadow: none !important;
  display: flex;
  width: 14em;
  flex-direction: row !important;
}

#topbar {
  width: 100%;
  background: #f9fafc;
}

.navbar-expand-md{
  padding: 0 25px 0 25px !important;
}
.nav-item {
  cursor: pointer;
}

.nav-item-active {
  border-bottom: 1px solid #2C7BE5;
  span {
    color: black;
  }
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 0.95rem !important;
  span {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 600;
  }
}

.topBarShareBtn,
.topBarCloseBtn {
  width: 30px !important;
  height: 30px !important;
  margin-left: 14px;
}

.topBarCloseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.greyB{
    width: 180px;
    border-radius: 4px;
    border-width: 0;
    // margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 700;
    background: #EDF2F9;
    
    color: #6E84A3;
  }
.downloadModal{
  margin-left: 45%;
  margin-bottom: 20px;
}
.downloadTitle{
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  margin-left: 35%;
  color: #12263F;

}
.downloadDesc{
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  
  color: #6E84A3;
}
.primary-buttonB{
  width: 98%;
  border-radius: 4px;
  border-width: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #2c7be5;
  color: #ffffff;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>
