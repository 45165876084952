<template>

  <div>
    <OptionsPanel>
      <Option>
        <PSelection
          title="Location Visitor Datasets"
          desc="These datasets will provide information on time of visits, and exact location visits."
          :reports="locVisReportsFiltered"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        ></PSelection>
      </Option>
    </OptionsPanel>
    <OptionsPanel>
      <Option>
        <PSelection
          title="Foot Traffic Datasets"
          desc="These datasets will provide trend and summary information on visitors to a location."
          :reports="footTrafficReports"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        >
          <template v-slot:option>
            <!--template v-if="isReportIdSelected('ESTIMATED_VISITS_REPORT') || isReportIdSelected('ESTIMATED_VISITS_2_REPORT')">
              <EstimatedVisitsOptions></EstimatedVisitsOptions>
            </template-->
            <!-- <template v-if="isReportIdSelected('HEARTBEAT_REPORT')">
              <Heartbeat></Heartbeat>
            </template> -->
          </template>
        </PSelection>
      </Option>
    </OptionsPanel>
    <OptionsPanel>
      <Option>
        <PSelection
          :multiSelect="true"
          title="Common Evening and Common Daytime Location Datasets"
          desc="These datasets will provide information about where your visitors spend their time in the evenings and workdays."
          :reports="CECDLocationReportsFiltered"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        >
          <template v-slot:inline-option>
            <template
              v-if="
                jobRequest.didReportTypes.some((id) =>
                  id.includes('CEL_CDL')
                ) ||
                (jobRequest.didReportType !== undefined &&
                  jobRequest.didReportType.includes('CEL_CDL'))
              "
            >
              <CEDEReport></CEDEReport>
            </template>
          </template>

          <template v-slot:option>
            <template
              v-if="
                isReportIdSelected('RESIDENT_WORKER_REPORT') ||
                isReportIdSelected('VISITORS_HOME_REPORT') ||
                isReportIdSelected('CEL_CDL_LOCATION_REPORT')
              "
            >
              <ResidentWorkerReport></ResidentWorkerReport>
            </template>
            <template
              v-if="
                isReportIdSelected('GENERATOR_REPORT') &&
                hasPermission(
                  'Network',
                  $persistingStore.state.user.permissions
                )
              "
            >
              <GeneratorReport></GeneratorReport>
            </template>
          </template>
        </PSelection>
      </Option>
    </OptionsPanel>
    <OptionsPanel v-if="!isLargeJob">
      <Option>
        <PSelection
          :multiSelect="true"
          title="Visitor/Behavioral Datasets"
          desc="These datasets provide information about behavior of the visitors seen in a particular location over a set time frame."
          :reports="visitorBehavioralReports"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        >
          <template v-slot:option>
            <template v-if="isReportIdSelected('GEOSOCIAL_AFFINITY_REPORT')">
              <GeoSocialAffinity></GeoSocialAffinity>
            </template>
            <template v-if="isReportIdSelected('LOCATION_AFFINITY_REPORT')">
              <LocationAffinityReport></LocationAffinityReport>
            </template>
          </template>
        </PSelection>
      </Option>
    </OptionsPanel>
    <OptionsPanel v-if="!isLargeJob">
      <Option>
        <PSelection
          title="Path-to-Purchase Datasets"
          desc="These datasets will provide information on where visitors were before or after visiting a location, or movement patterns during a time period."
          :reports="p2pReports"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        >
          <template v-slot:option>
            <template v-if="isReportIdSelected(pathingOptions)">
              <PathingReportOptions></PathingReportOptions>
              <template v-if="showJSON">
                <POIjson></POIjson>
              </template>
            </template>
            <template v-if="isReportIdSelected('CLUSTER_REPORT')">
              <ClusterReportOptions></ClusterReportOptions>
            </template>
          </template>
        </PSelection>
      </Option>
    </OptionsPanel>
    <OptionsPanel v-if="!isLargeJob">
      <Option>
        <PSelection
          title="Location Datasets"
          desc="Understand further information about your locations, including trade areas and dwell times."
          :reports="locationReports"
          :isReportActive="isReportActive"
          @selectReport="selectReport"
        >
          <template v-slot:option>
            <template v-if="isReportIdSelected('OGS')">
              <OGSReport></OGSReport>
            </template>
            <template v-if="isReportIdSelected('DWELL_TIME')">
              <DwellTimeReport></DwellTimeReport>
            </template>
          </template>
        </PSelection>
      </Option>
    </OptionsPanel>
  </div>
</template>

<script>
import * as reportTypes from "./reportTypes.js";
import Option from "./shared/Option.vue";
import OptionsPanel from "./shared/OptionsPanel.vue";
import JobService from "@/services/dataExplorer/jobService.js";
import { PSelection } from "pinnacle-lib";
import EstimatedVisitsOptions from "@/components/dataExplorer/reportOptions/estimatedVisitsOptions.vue";
import CEDEReport from "@/components/dataExplorer/reportOptions/CEDEReport.vue";
import ResidentWorkerReport from "@/components/dataExplorer/reportOptions/ResidentWorkerReport.vue";
import GeneratorReport from "@/components/dataExplorer/reportOptions/GeneratorReport.vue";
import GeoSocialAffinity from "@/components/dataExplorer/reportOptions/GeoSocialAffinity.vue";
import Heartbeat from "@/components/dataExplorer/reportOptions/Heartbeat.vue";
import LocationAffinityReport from "@/components/dataExplorer/reportOptions/LocationAffinityReport.vue";
import PathingReportOptions from "@/components/dataExplorer/PathingReportOptions.vue";
import ClusterReportOptions from "@/components/dataExplorer/reportOptions/ClusterReportOptions.vue";
import OGSReport from "@/components/dataExplorer/reportOptions/OGSReport.vue";
import DwellTimeReport from "@/components/dataExplorer/reportOptions/DwellTimeReport.vue";
import POIjson from "@/components/dataExplorer/reportOptions/POIjson.vue";
import { bus } from "@/main";
import { syncState } from "../../utils/syncState";
export default {
  mixins: [JobService],
  components: {
    Option,
    OptionsPanel,
    EstimatedVisitsOptions,
    CEDEReport,
    ResidentWorkerReport,
    GeneratorReport,
    GeoSocialAffinity,
    Heartbeat,
    LocationAffinityReport,
    ClusterReportOptions,
    OGSReport,
    DwellTimeReport,
    PathingReportOptions,
    POIjson,
    PSelection
  },
  data() {
    return {
      locVisReports: reportTypes.locVisReportsDetails,
      footTrafficReports: reportTypes.footTrafficReportsDetails,
      CECDLocationReports: reportTypes.CECDLocationReports,
      visitorBehavioralReports: reportTypes.visitorBehavioralReports,
      p2pReports: reportTypes.p2pReports,
      locationReports: reportTypes.locationReportsDetails,
      showJSON: false,
      pathingOptions: [
        "PATHING_X_REPORT",
        "PATHING_X_CONTEXT_REPORT",
        "PATHING_X_CONTEXT_ONLY_REPORT",
        "PRE_POST_REPORT",
        "PRE_POST_SAMPLED_REPORT",
        "FEEDER_REPORT",
      ],
    };
  },
  methods: {
    isReportActive(report) {
      const { id } = report;
      if (report.formats !== undefined) {
        return Object.keys(report.formats).some((format) =>
          this.isReportIdSelected(format)
        );
      }
      return this.isReportIdSelected(id);
    },
    selectReport(payload = {}) {
      const { action, report } = payload;
      if (!action || !report)
        throw new Error("Tried to select a report with missing payload.");
      let type = report.type;
      let postfix = "ReportType";
      let reportId = report.id;
      if (type === "loc") {
        type = "Location";
      } else if (type === "cl" || type === "did") {
        type = "DID";
        postfix = "ReportTypes";
      } else if (reportId === "PATHING_REPORT") {
        reportId = this.pathingReportOption;
        type = "P2P";
      } else {
        type = type.toUpperCase();
      }
      const hasFormats = report.formats !== undefined;
      if (action === "remove") {
        if (type === "DID") {
          if (hasFormats) {
            this.$sessionStore.commit(
              `set${type}${postfix}`,
              this.jobRequest.didReportTypes.filter(
                (id) => report.formats[id] === undefined
              )
            );
          } else {
            this.$sessionStore.commit(
              `set${type}${postfix}`,
              this.jobRequest.didReportTypes.filter((id) => id !== report.id)
            );
          }
        } else {
          this.$sessionStore.commit(`set${type}${postfix}`, "");
        }
      } else if (action === "add") {
        if (hasFormats) {
          if (type === "DID") {
            this.$sessionStore.commit(`set${type}${postfix}`, [
              ...this.jobRequest.didReportTypes,
              Object.values(report.formats)[0].id,
            ]);
            console.log(this.jobRequest.didReportTypes);
          } else {
            this.$sessionStore.commit(
              `set${type}${postfix}`,
              Object.values(report.formats)[0].id
            );
          }
        } else {
          if (type === "DID") {
            this.$sessionStore.commit(`set${type}${postfix}`, [
              ...this.jobRequest.didReportTypes,
              report.id,
            ]);
          } else {
            this.$sessionStore.commit(`set${type}${postfix}`, reportId);
          }
        }
      }
    },
  },
  mounted() {
    const { contextPoiGeojson } = this.$sessionStore.state.jobRequest;
    if (contextPoiGeojson !== undefined) {
      this.showJSON = true;
    }
    bus.$on("showJSON", (res) => {
      this.showJSON = res;
    });
  },
  computed: {
    locVisReportsFiltered() {
      let reports = this.locVisReports;
      if (this.isLargeJob) {
        reports = reports.filter((r) => r.id !== "CROSS_SHOPPING_REPORT");
      }
      return reports;
    },
    CECDLocationReportsFiltered() {
      let reports = this.CECDLocationReports;
      if (this.isLargeJob) {
        reports = reports.filter((r) => r.id == "BASE_CEL_CDL_REPORT");
      }
      console.log(reports);
      return reports;
    },
    permissions() {
      return this.$persistingStore.state.user.permissions;
    },
    ...syncState({
      isLargeJob: "state.jobRequest.isLargeJob|setIsLargeJob"
    }, { context: this, defaultStore: '$sessionStore' })
  },
  watch: {
    // this watcher is set up to add or remove access
    // to the Uniques and Extrapolated Visit Estimation reports
    permissions: {
      immediate: true,
      deep: true,
      handler(permissions) {
        // when permissions changes, the relevant permissions are evaluated
        const uniquePermission = permissions.find(
          (item) => item.name === "Uniques Report"
        );
        const extrapolatedPermission = permissions.find(
          (item) => item.name === "Extrapolated Visit Estimation"
        );
        const superAdmin = permissions.find(
          (item) => item.name === "Super Admin"
        );

        // superAdmin allows for access to both automatically
        if (superAdmin) {
          this.locVisReports[1] = reportTypes.locVisReportsDetails[1];
          this.footTrafficReports[2] = reportTypes.footTrafficReportsDetails[2];
          return;
        }

        // if no superAdmin, each report is evaluated individually and
        // added/removed/left alone based on the change
        if (uniquePermission) {
          this.locVisReports[1] = reportTypes.locVisReportsDetails[1];
        } else {
          this.locVisReports[1] = { id: false };
        }

        if (extrapolatedPermission) {
          this.footTrafficReports[2] = reportTypes.footTrafficReportsDetails[2];
        } else {
          this.footTrafficReports[2] = { id: false };
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .de-option:not(:first-child) {
  margin-top: 1rem;
}

::v-deep .de-option:not(:last-child) {
  // border-bottom: 0.5px solid #e3ebf6;
  padding-bottom: 1rem;
}

</style>