<template>
  <div class="datasets-table-container">
    <DataTable>
      <thead>
        <tr>
          <th id="id-header" v-if="isSuperAdmin">ID</th>
          <th id="name-header">Name</th>
          <th id="status-header">Status</th>
          <th id="report-type-header">Report Type</th>
          <th id="company-header" v-if="isSuperAdmin">Company</th>
          <th id="created-on-header">Created On</th>
          <th id="blank-header"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in reports" style="cursor:pointer;" @click="openReport(report)" :key="`${report.id}-${report.created_at}`"
          :class="{ 'highlight-item': submitted === report.id }">
          <td v-if="isSuperAdmin" class="action-items-col">{{ report.id }}</td>
          <td class="name-col">
            <IconWrapper :src="require('pinnacle-lib/assets/icon/blank_file_blue.svg')"></IconWrapper>
            <p class="name">{{ report.name }}</p>
          </td>
          <td class="status-badge-container">
            <StatusBadge :text="getFriendlyStatusName(report.project_status)"
              :variant="STATUS_VARIANT[report.project_status] || 'error'"></StatusBadge>
          </td>
          <td>{{ getReportType(report) }}</td>
          <td v-if="isSuperAdmin">{{ getCompanyNameById(report.permissions_company_id) }}</td>
          <td>{{ report.created_at | convertTime }}</td>
          <td class="options-dropdown-col" @click.stop="true">
            <b-dropdown right class="options-dropdown" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <img alt="report options button" :src="require('pinnacle-lib/assets/icon/more-vertical.svg')" />
              </template>
              <b-dropdown-item @click="openReport(report)">Open</b-dropdown-item>
              <b-dropdown-item @click="openShareModal(report)">Share</b-dropdown-item>
              <b-dropdown-item @click="openExportPDFModal(report)">Export as PDF</b-dropdown-item>
              <b-dropdown-item @click="cloneReport(report)">Clone</b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </DataTable>
    <div v-if="loading.loadReports" class="text-center w-100 my-2" style="color: #6e84a3">
      <!-- <b-icon icon="three-dots" animation="fade" font-scale="4"></b-icon> -->
      <WorldLoader></WorldLoader>
    </div>
    <div v-if="!loading.loadReports" class="text-center w-100 my-2">
      <b-button v-if="hasMore" @click="loadMore()" class="m-md-3"
        style="background-color: #edf2f9; color: #6e84a3; border-width: 0px" size="md">
        Load More
      </b-button>
      <b-button v-if="!hasMore" :disabled="true" class="m-md-3"
        style="background-color: #edf2f9; color: #6e84a3; border-width: 0px" size="md">
        End of Results
      </b-button>
    </div>
    <!-- Share modal -->
    <b-modal size="xl" centered hide-footer scrollable :visible="$route.query.share !== undefined"
      @hidden="removeShareQuery()" @shown="getShares()">
      <template #modal-header>
        <div class="header">
          <div class="title-bar">
            <h2>Share your Report: <span class="title">"{{ shareReport.name }}"</span></h2>
            <img class="close-button" @click="closeShareModal()" alt="close"
              :src="require('pinnacle-lib/assets/icon/close-icon.svg')" />

          </div>
          <div class="modal-description">
            <p>Create a link to share interactive visualization reports with anyone. A Pinnacle account is not required
              to access shared reports. Share links can be deactivated at any time.</p>
          </div>

        </div>
      </template>
      <div class="body">
        <div class="modal-section">
          <div class="section-header">
            <p>Add Link</p>
          </div>
          <div class="add-link-section">
            <b-input :disabled="disabled.createShare" v-model="name" class="add-link-input" type="text"
              placeholder="Enter share link name"></b-input>
            <b-button :disabled="disabled.createShare" @click="createShare()" variant="primary"
              class="add-link-btn">Create New</b-button>
          </div>
        </div>
        <div class="modal-section">
          <div class="section-header">
            <p>Created Links</p>
          </div>
          <div class="section-content">
            <DataTable>
              <thead>
                <tr>
                  <th id="name">Name</th>
                  <th id="status">Status</th>
                  <th id="created">Created</th>
                  <th id="link">Link</th>
                  <th id="share-options-header"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(share, index) in shares" :key="share.id">
                  <td class="name-col">
                    <IconWrapper :src="require('pinnacle-lib/assets/icon/link.svg')"></IconWrapper>
                    <p class="name">{{ share.name }}</p>
                  </td>
                  <td class="status-badge-container">
                    <StatusBadge :text="getShareStatus(share)" :variant="getShareStatusVariant(share)"></StatusBadge>
                  </td>
                  <td>{{ share.created_at | friendlyDate }}</td>
                  <td><a v-if="share.share_hash !== ''" :href="origin + share.share_hash" target="_blank">Open Tab</a>
                  </td>
                  <td class="options-dropdown-col">
                    <b-dropdown right class="options-dropdown" variant="link" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <img alt="share-options-button" :src="require('pinnacle-lib/assets/icon/more-vertical.svg')" />
                      </template>
                      <b-dropdown-item v-if="!share.deleted"
                        @click="deleteShare(share.share_hash, index)">Deactivate</b-dropdown-item>
                      <b-dropdown-item v-else @click="activateShare(share.share_hash, index)">Activate</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </DataTable>
            <div v-if="loading.getShares" class="text-center w-100 my-2" style="color: #6e84a3">
              <WorldLoader></WorldLoader>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <br />
      </template>
    </b-modal>
    <!-- Export PDF modal -->
    <ExportPDFModal></ExportPDFModal>
    <!-- Clone modal -->
    <LoadingModal :visible="clone !== undefined">
      <template #title>
        <h2>Cloning your Report: <span class="title">"{{ clone ? clone.name : 'Undefined' }}"</span></h2>
      </template>
    </LoadingModal>
  </div>
</template>

<script>
import { DataTable, GetMixin, StatusBadge } from "pinnacle-lib";
import CardUtil from "../../../services/cards/cardUtil";
import moment from "moment";
import { map, uniq } from "lodash";
import { syncState } from "../../../utils/syncState";
import jobService from "../../../services/dataExplorer/jobService";
import LoadingModal from "./LoadingModal.vue";
import IconWrapper from "./create/IconWrapper.vue";
import WorldLoader from "./WorldLoader.vue";
import RouteGuard from "pinnacle-lib/mixins/RouteGuard";
import ExportPDFModal from "./ExportPDFModal.vue";

export const REPORT_STATUS = {
  All: "",
  "In Progress": "In Progress",
  Completed: "Completed",
};

const STATUS_VARIANT = {
  "In Progress": "pending",
  Completed: "success",
};

const PAGE_SIZE = 25;

export default {
  mixins: [GetMixin, CardUtil, jobService, RouteGuard],
  components: { DataTable, StatusBadge, LoadingModal, IconWrapper, WorldLoader, ExportPDFModal },
  data() {
    return {
      clone: undefined,
      cloneLoading: false,
      reports: [],
      debounce: {
        search: null,
      },
      loading: {
        loadReports: false,
        getShares: false,
      },
      loadReportController: new AbortController(),
      disabled: {
        createShare: false
      },
      shares: [],
      name: '',
      hasMore: false,
      page: 1,
      templates: [],
      companyMapping: {},
      REPORT_STATUS,
      STATUS_VARIANT,
      PAGE_SIZE
    };
  },
  created() {
    this.loadReports();
    this.provisionTemplates();
    this.loadCompanies();
  },
  computed: {
    hasReportPermission() {
      return this.hasPermission('Card Report', this.permissions);
    },
    permissions() {
      return this.$persistingStore.state.user.permissions;
    },
    /**
     * The ID of the recently submitted report (from query params).
     */
    submitted() {
      return this.$route.query.submitted;
    },
    ...syncState(
      {
        searchTerm: "state.reportFilters.searchTerm",
        status: "state.reportFilters.status",
        shouldReload: "state.reportFilters.reload|setReportFeedReload",
        companies: "state.reportFilters.companies|setReportFeedCompanies",
        companySearchTerm: "state.reportFilters.companySearchTerm|setReportFeedCompanySearchTerm",
        company: "state.reportFilters.company"
      },
      { context: this, defaultStore: "$store" }
    ),
    origin() {
      return window.location.origin + '/share/';
    },
    /**
     * The shared report.
     * @return Returns the parsed 'share' query param.
     */
    shareReport() {
      if (this.$route.query.share) {
        return JSON.parse(atob(this.$route.query.share));
      }
      return {};
    },
    isSuperAdmin() {
      return this.hasPermission('Super Admin', this.$persistingStore.state.user.permissions);
    },
  },
  watch: {
    searchTerm() {
      this.loadReports();
    },
    status() {
      this.loadReports();
    },
    company() {
      this.loadReports();
    },
    companySearchTerm() {
      this.loadCompanies();
    },
    shouldReload(reload) {
      if (reload) {
        this.loadReports();
        this.shouldReload = false;
      }
    }
  },
  filters: {
    convertTime(timeStr) {
      return moment(timeStr).format("MM/DD/YYYY HH:mm:ss");
    },
    friendlyDate(date) {
      if (date === '') return date;
      return moment(date).format('MM/DD/YYYY');
    }
  },
  methods: {
    getShareStatus(share) {
      if (share.pending) return 'Pending';
      if (share.deleted) return 'Inactive';
      return 'Active';
    },
    getShareStatusVariant(share) {
      if (share.pending) return 'pending';
      if (share.deleted) return 'disabled';
      return 'success';
    },
    /**
     * Sends the user to the report page.
     * @param {*} report 
     */
    openReport(report) {
      if (report.project_type == 'visit_report') {
        this.$router.push({
          path: `/home/visit-reports/${report.id}`
        });
      } else {
        this.$router.push({
          path: `/home/reports/${report.id}`
        });
      }
    },
    /**
     * Opens the share modal for a given report.
     * @param {*} report 
     */
    openShareModal(report) {
      const clone = { id: report.id, name: report.name };
      this.$router.push({
        query: {
          share: btoa(JSON.stringify(clone))
        }
      });
    },
    /**
     * Closes the share modal.
     */
    closeShareModal() {
      this.$router.push({ name: 'home-reports' });
      this.shares = [];
    },
    /**
     * Opens the export as PDF modal for a given report.
     * @param {*} report 
     */
     openExportPDFModal(report) {
      const clone = { id: report.id, name: report.name };
      this.$router.push({
        query: {
          exportPDF: btoa(JSON.stringify(clone))
        }
      });
    },
    /**
     * Returns the company name by ID.
     * @param {*} id 
     */
    getCompanyNameById(id) {
      if (id in this.companyMapping) return this.companyMapping[id];
      return id.toString();
    },
    /**
     * Returns the key of the report status (the friendly name).
     */
    getFriendlyStatusName(jobStatus) {
      // Get the key by value of REPORT_STATUS
      return Object.keys(REPORT_STATUS).filter(
        (status) => REPORT_STATUS[status] === jobStatus
      )[0] || 'Failed';
    },
    /**
     * Returns the template name by template key.
     * @param {*} key 
     */
    getTemplateNameByKey(key) {
      if (this.templates) {
        const matched = this.templates.find((el) => el.key === key);
        if (matched) {
          return matched.name;
        }
      }
      return key;
    },
    getReportType(report) {
      if (report.project_type == 'visit_report') {
        return "Visits Report"
      } else {
        return this.getTemplateNameByKey(report.template_key);
      }
    },
    /**
     * Updates the filters.
     * @param {*} params 
     */
    _setFilters(params) {
      if (this.searchTerm !== "")
        params.search_term = `%${this.searchTerm}%`;
      if (this.status.filter !== REPORT_STATUS.All)
        params.status = this.status.filter;
      if (typeof this.company.filter === 'number')
        params.company_id = this.company.filter;
      else if (this.company.filter === '__MY_PROJECTS') {
        params.from_user = true;
      }
      if (!this.hasPermission('Pinnacle Visits Report Access', this.permissions)) {
        params.project_type = 'report';
      }
    },
    /**
     * Gets the shares from shareReport computed param.
     */
    async getShares() {
      this.shares = [];
      this.loading.getShares = true;
      try {
        const res = await this.getCardData(`/v2/get-shares/${this.shareReport.id}`, true);
        if (res.data.message === 'success') {
          this.shares = res.data.data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.getShares = false;
      }
    },
    /**
     * Deletes a share from the shareReport computed param.
     * @param {*} hash 
     * @param {*} index 
     */
    async deleteShare(hash, index) {
      this.$set(this.shares, index, { ...this.shares[index], pending: 1 });
      try {
        const params = {
          hash, project_id: this.shareReport.id
        };
        const res = await this.postCardData(`/v2/delete-shares`, params);
        if (res.data.message === 'success') {
          this.$set(this.shares, index, { ...this.shares[index], deleted: 1 });
        }
      } catch (err) {
        console.log(err);
      } finally {
        const clone = { ...this.shares[index] };
        delete clone.pending;
        this.$set(this.shares, index, clone);
      }
    },
    /**
     * Activates a deleted share.
     * @param {*} hash 
     * @param {*} index 
     */
    async activateShare(hash, index) {
      this.$set(this.shares, index, { ...this.shares[index], pending: 1 });
      try {
        const params = {
          hash, project_id: this.shareReport.id
        };
        const res = await this.postCardData(`/v2/activate-shares`, params);
        if (res.data.succeeded) {
          this.$set(this.shares, index, { ...this.shares[index], deleted: 0 });
        }
      } catch (err) {
        console.log(err);
      } finally {
        const clone = { ...this.shares[index] };
        delete clone.pending;
        this.$set(this.shares, index, clone);
      }
    },
    /**
     * Creates a share.
     */
    async createShare() {
      if (this.name === '') this.name = `Share link #${this.shares.length + 1}`;
      this.disabled.createShare = true;
      try {
        const params = {
          name: this.name,
          project_id: this.shareReport.id
        };
        const res = await this.postCardData(`/v2/create-shares`, params);

        if (res.data.message === 'success') {
          this.name = '';
          this.getShares();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled.createShare = false;
      }
    },
    /**
     * Fetches the names of companies associated with the user.
     * @returns {{names: Array}} The names of companies.
     */
    async loadCompanies() {
      this.companies = [];

      const params = {
        only_has_reports: true,
      };

      if (this.companySearchTerm !== '') {
        params.search_term = this.companySearchTerm;
      }

      try {
        const res = await this.getFromURL(
          `${process.env.VUE_APP_PINNACLE_API}/v2/get-companies`,
          { params }
        );
        this.companies = res.companies;
        return res.companies;
      } catch (err) {
        console.log(err);
      }
      return [];
    },
    /**
     * Fetches the company names with a map from the ids.
     * @param {*} ids
     */
    async getCompanyNames(ids) {
      if (ids.length < 1) return [];
      const params = {
        filter: {
          where: [`id~=${ids.join("|")}`],
        },
      };
      try {
        const res = await this.getFromURL(
          `${process.env.VUE_APP_PINNACLE_API}/v2/get-companies`,
          { params }
        );
        const mapping = {};
        res.companies.forEach((company) => {
          mapping[company.id] = company.name;
        });
        return mapping;
      } catch (err) {
        console.log(err);
      }
      return [];
    },
    /**
     * Deletes a report.
     * @param {*} id 
     */
    async deleteReport(id) {
      const data = await this.getCardData(
        `${process.env.VUE_APP_PINNACLE_API}/v2/del-project/${id}`, true
      );
      if (data.data.message === "success") {
        this.loadReports();
      }
    },
    /**
     * Loads visualization reports and updates the component state.
     */
    async loadReports() {
      this.loading.loadReports = true;
      if (this.loadReportController !== undefined) {
        this.loadReportController.abort();
      }
      this.reports = [];
      this.page = 1;
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/get-insights`;
        const params = {
          page: this.page,
          page_size: this.PAGE_SIZE,
        };

        this._setFilters(params);
        this.loadReportController = new AbortController();
        let data = await this.getFromURL(urlStr, { params, signal: this.loadReportController.signal });
        const [reports, pageInfo] = data;
        this.reports = reports;

        const ids = uniq(map(reports, "permissions_company_id"));
        this.companyMapping = await this.getCompanyNames(ids);

        if (this.reports.length < pageInfo[0].totalPageCount) {
          this.hasMore = true;
        } else {
          this.hasMore = false;
        }
        this.loading.loadReports = false;
      } catch (err) {
        console.log(this.loadReportController)
        if (this.loadReportController !== undefined && this.loadReportController.signal.aborted) {
          this.loading.loadReports = true;
          return;
        }
        this.loading.loadReports = false;
        console.log(err);
      } finally {
        this.loadReportController = undefined;
      }
    },
    /**
     * Loads the next page of data jobs.
     */
    async loadMore() {
      this.loading.loadReports = true;
      if (this.loadReportController !== undefined) {
        this.loadReportController.abort();
      }
      try {
        let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/get-insights`;
        this.page += 1;
        const params = {
          page: this.page,
          page_size: this.PAGE_SIZE,
        };
        this._setFilters(params);
        this.loadReportController = new AbortController();
        let data = await this.getFromURL(urlStr, { params, signal: this.loadReportController.signal });
        const [reports, pageInfo] = data;
        this.reports = this.reports.concat(reports);

        if (this.reports.length < pageInfo[0].totalPageCount) {
          this.hasMore = true;
        } else {
          this.hasMore = false;
        }
        this.loading.loadReports = false;
      } catch (err) {
        if (this.loadReportController !== undefined && this.loadReportController.signal.aborted) {
          this.loading.loadReports = true;
          return;
        }
        this.loading.loadReports = false;
        console.log(err);
      } finally {
        this.loadReportController = undefined;
      }
    },
    async cloneReport(report) {

      let jobDetails;

      if (!report.job_template) {
        this.clone = report;
        this.cloneLoading = true;
        // Fetch the job details
        jobDetails = await this.loadJobDetails(report.job_id);
        if (jobDetails === undefined) {
          this.cloneLoading = false;
          return;
        }

      } else {
        jobDetails = JSON.parse(report.job_template);
      }

      jobDetails = jobDetails.createJobRequest;
      const template = this.templates.find((template) => template.id === report.template_id);
      const { startDateTime, endDateTime, polygonInputOptions } = jobDetails;

      const recreate = {
        name: report.name,
        template,
        startDateTime,
        endDateTime,
        polygonInputOptions
      };
      console.log(recreate);
      this.$sessionStore.commit('recreateReport', recreate);
      this.$router.push({ name: 'create-report-places', query: { clone: true } });
    },
    /**
     * Clears the submitted field from query params.
     */
    removeReportQuery() {
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query);
        if ('submitted' in query) {
          delete query.submitted;
          this.$router.replace({ query });
        }
      }, 100);
    },
    /**
     * Clears the share field from query params.
     */
    removeShareQuery() {
      this.shares = [];
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query);
        if ('share' in query) {
          delete query.share;
          this.$router.replace({ query });
        }
      }, 100);
    },
    handleModalHide(evt) {
      evt.preventDefault();
    }
  },
};
</script> 

<style lang="scss" scoped>
.modal-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  cursor: pointer;
}

.name-col {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 14px;
  margin-bottom: 0;
}

p,
h2 {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  flex: 1;

  .modal-description {
    width: 65%;

    p {
      color: #858EA7;
    }
  }
}

.title-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  .title {
    color: #6E84A3;
  }

  h2 {
    font-weight: 700;
  }

  img {
    width: 22px;
    height: 22px;
  }
}

.section-header {
  p {
    color: #6E84A3;
    margin-bottom: 0.3rem;
  }
}

::v-deep .modal-header,
::v-deep .modal-body {
  padding: 3rem !important;
}

::v-deep .modal-body {
  padding-top: 0 !important;
}


.modal-section+.modal-section {
  margin-top: 2rem;
}

.add-link-section {
  display: flex;

  .add-link-input {
    flex: 0.9;
  }

  .add-link-btn {
    margin-left: 0.3rem;
    flex: 0.1;
  }
}

.options-dropdown-col {
  text-align: right;
}
</style>