<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import cleanCrumb from "@/utils/cleanCrumb.js";

export default {
  name: "App",
  methods: {},
  mounted() {
    if (this.$sessionStore.state.crumbs.trail.length === 0) {
      this.$sessionStore.commit('addCrumb', cleanCrumb(this.$route));
    }
  },
  watch: {
    $route(to, from) {
      if (typeof analytics !== "undefined") {
        analytics.page(to.fullPath, to.name)
        analytics.track('page view', {
          company_id: this.$persistingStore.state.user.data.company_id,
          id: this.$persistingStore.state.user.data.id,
          email: this.$persistingStore.state.user.data.email,
        })
      }
      if (window.popStateDetected) {
        window.popStateDetected = false;
        this.$sessionStore.commit("popCrumb");
        return;
      }
      if (to.meta.ignoreCrumb) {
        delete to.meta.ignoreCrumb;
        return;
      }
      console.log(to.path, from.path);
      this.$sessionStore.commit("addCrumb", to);
    },
  },
};
</script>

<style>
</style>
