<template>
  <div class="card chart-card" id="ReportTilte">
    <div class="d-flex align-items-center justify-content-between">
      <div class="">
        <h2>{{ reportDetails.name }}</h2>
        <p>Time Frame: {{ reportDetails.start_date_time | formatDate }} - {{ reportDetails.end_date_time | formatDate}}</p>
      </div>
      <div v-if="false">
        <b-button @click="exportReport()" class="exportBtn" variant="outline-primary">Export Report</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reportDetails", "exportReport"],
  methods: {


  }
}
</script>
<style lang="scss" scoped>
@import "./ReportChartStyles.scss";
</style>