<template>
  <div class="polygon-library">
    <b-input
      v-model="searchTerm"
      @input="searchPolygons"
      placeholder="Search Places"
    ></b-input>
    <div class="filters-container">
      <template>
        <b-form-group v-for="filter in filters" :key="filter.id">
          <b-form-tags v-model="bsTags[filter.id]" class="tags-container">
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <b-dropdown
                class="filter-dropdown"
                variant="outline-secondary"
                block
                menu-class="w-100"
                :title="filter.name"
                @shown="refHandler(filter.id)"
              >
                <template #button-content>
                  {{ filter.name | str_limit(buttonTruncation) }}
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-input
                    autofocus
                    v-model="filterSearchTerms[filter.id]"
                    type="search"
                    autocomplete="off"
                    @focus="updateOptions(filter)"
                    @input="updateOptions(filter)"
                    :ref='filter.id'
                  ></b-form-input>
                </b-dropdown-form>
                <b-dropdown-divider
                  v-if="filterOptions[filter.id].length > 0"
                ></b-dropdown-divider>
                <b-dropdown-item-button
                  v-for="option in filterOptions[filter.id]"
                  :key="option.id"
                  @click="onOptionClick({ filter, option, addTag })"
                  >{{ option.name | str_limit(suggestionTruncation) }}</b-dropdown-item-button
                >
                <!-- <b-dropdown-text v-if="">
                No results found.
              </b-dropdown-text> -->
              </b-dropdown>
              <ul v-if="tags.length > 0" class="selected-tags list-inline d-inline-block">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeOption({ tag, filter, removeTag })"
                    :title="tag"
                    :disabled="disabled"
                    variant="primary"
                  >
                    {{ tag }}
                  </b-form-tag>
                </li>
              </ul>
            </template>
          </b-form-tags>
        </b-form-group>
      </template>
    </div>
    <template v-if="hasUserParams() || hasSearchParams() || selectedPolygons.length > 0">
      <b-button
        @click="clearAll"
        class="clear-button"
        variant="outline-secondary"
        >Clear Filters & Selections</b-button
      >
    </template>
    <div v-if="loading" class="text-center" 
      style="margin-top: -15px;color: #6e84a3;"
    >
      <WorldLoader></WorldLoader>
    </div>
    <div v-show="(polygons.length > 0 || pagination.records === 0 || selectedPolygons.length > 0) && !loading">
      <b-collapse :visible="polygons.length > 0 || selectedPolygons.length > 0">

        <div class="results-container">
          <EvePolygonTable
            @updateStore="updateStore"
            ref="polygonTable"
            @changePage="updatePagination"
            :polygons="polygons"
            :pagination="pagination"
            :display="displaySelected"
            :allowMultiple="allowMultiple"
          >
          </EvePolygonTable>
        </div>
      </b-collapse>
      <b-collapse :visible="pagination.records === 0">
        <!-- Show no results message -->
        <div>No results found.</div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import EveVPService from "@/services/dataExplorer/eveVPService.js";
import JobService from "@/services/dataExplorer/jobService.js";
import EvePolygonTable from "./EvePolygonTable.vue";

import { syncState } from '../../utils/syncState.js';
import WorldLoader from "../home/new/WorldLoader.vue";

export default {
  mixins: [JobService, EveVPService],
  components: { EvePolygonTable, WorldLoader },
  props: ['allowMultiple'],
  created() {
    this.initFilterMaps();
  },
  data() {
    let buttonTruncation, suggestionTruncation;
    if (window.innerWidth < 1100) {
      buttonTruncation = Math.floor(window.innerWidth / 150);
    } else if (window.innerWidth < 1400) {
      buttonTruncation = Math.floor(window.innerWidth / 75);
    } else {
      buttonTruncation = 32;
    }
    if (window.innerWidth < 1300) {
      this.suggestionTruncation = 12;
    } else if (window.innerWidth < 1500) {
      this.suggestionTruncation = 20;
    } else {
      this.suggestionTruncation = 32;
    }

    return {
      loading: false,
      filterSearchTerms: {},
      filterTags: {},
      filterOptions: {},
      filterDebounce: null,
      searchDebounce: null,
      polygons: [],
      pagination: {
        page: 1,
        page_size: 25,
        pages: null,
        records: null,
      },
      searchTerm: "",
      bsTags: {},
      buttonTruncation: buttonTruncation,
      suggestionTruncation: suggestionTruncation
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1100) {
        this.buttonTruncation = Math.floor(window.innerWidth / 150);
      } else if (window.innerWidth < 1400) {
        this.buttonTruncation = Math.floor(window.innerWidth / 75);
      } else {       
        this.buttonTruncation = 32;
      }

      if (window.innerWidth < 1300) {
        this.suggestionTruncation = 12;
      } else if (window.innerWidth < 1500) {
        this.suggestionTruncation = 20;
      } else {
        this.suggestionTruncation = 32;
      }
    });
  },
  watch: {
    "pagination.page"() {
      this.updatePolygons(false);
    },
  },
  computed: {
    displaySelected() {
      if (this.polygons.length === 0 && this.selectedPolygons.length > 0) {
        return false;
      }
      return true;
    },
    ...syncState({
      selectedPolygons: 'state.jobRequest.selectedPolygons',
      excludedPolygons: 'state.jobRequest.excludedPolygons',
      selectAllParams: 'state.jobRequest.selectAllParams'
    }, { context: this, defaultStore: '$sessionStore' }),
  },
  methods: {
    refHandler(filter){
        this.$nextTick(() => {
          this.$refs[filter][0].$el.focus()
        })    
    },
    initFilterMaps() {
      for (const filter of this.Filters) {
        this.filterSearchTerms[filter.id] = "";
        this.filterTags[filter.id] = [];
        this.filterOptions[filter.id] = [];
        this.bsTags[filter.id] = [];
      }
    },
    updateOptions(filter) {
      if (this.filterDebounce !== null) clearTimeout(this.filterDebounce);
      //if (this.filterSearchTerms[filter.id] === "") return;

      this.filterDebounce = setTimeout(async () => {
        let records = await this.searchCriteria(
          filter,
          {name: this.filterSearchTerms[filter.id]}
        );

        // if (filter.id === "subsector-filter") {
        //   records = records.filter((record) => record.parent === undefined);
        // }
        this.filterOptions[filter.id] = records.slice(0, 5);

        this.filterOptions = Object.assign({}, this.filterOptions);
      }, 200);
    },
    updateFilter(filter) {
      this.setFilter(
        filter,
        this.filterTags[filter.id].map((option) => option.id.toString())
      );
      if (this.hasUserParams() || this.hasSearchParams()) this.updatePolygons();
      else {
        this.polygons = [];
        this.pagination = {
          page: 1,
          page_size: 25,
          pages: null,
          records: null,
        };
      }
    },
    onOptionClick({ filter, option, addTag }) {
      if (this.filterTags[filter.id].some((opt) => opt.id === option.id))
        return;
      addTag(option.name);
      this.filterSearchTerms[filter.id] = "";
      this.filterOptions[filter.id] = [];
      this.filterTags[filter.id].push(option);
      this.updateFilter(filter);
    },
    removeOption({ filter, tag, removeTag }) {
      removeTag(tag);
      this.filterTags[filter.id] = this.filterTags[filter.id].filter(
        (option) => option.name !== tag
      );
      this.updateFilter(filter);
    },
    searchPolygons(term) {
      if (term === "") this.clearSearchParams();
      else {
        if (this.searchDebounce !== null) clearTimeout(this.searchDebounce);
        this.searchDebounce = setTimeout(() => {
          this.setSearchParams(term);
          this.updatePolygons();
        }, 200);
      }
    },
    async updatePolygons(resetSelectAll = true) {
      try {
        if (resetSelectAll) {
          if (this.$refs.polygonTable) this.$refs.polygonTable.resetSelectAll();
        }
        this.loading = true;
        const data = await this.getEveFilteredPolygons(this.pagination);
        console.log(data);
        this.loading = false;
        this.pagination = data.pagination;
        this.polygons = data.records;
      } catch (axiosErr) {
        console.error("Could not fetch filtered polygons");
        console.log(axiosErr);
      }
    },
    updatePagination(page) {
      this.pagination.page = page;
    },
    updateStore({ selectAll }) {
      if (selectAll) {
        const selectAllParams = Object.assign({}, this._paramList);
        selectAllParams.page = 1;
        selectAllParams.page_size = this.pagination.records;
        this.$sessionStore.commit("setSelectAllParams", selectAllParams);
        
        return;
      } else {
        this.$sessionStore.commit("setExcludedPolygons", []);
        this.$sessionStore.commit("setSelectAllParams", undefined);

        return;
      }
    },
    clearAll() {
      this.clearAllParams();
      this.polygons = [];
      this.pagination = {
        page: 1,
        page_size: 25,
        pages: null,
        records: null,
      };
      this.searchTerm = "";
      this.initFilterMaps();
      this.filterDebounce = null;
      this.searchDebounce = null;
      if (this.$refs.polygonTable !== undefined) {
        this.$refs.polygonTable.clearSelections();
      }
      this.updateStore({ selectedPolygons: undefined, selectAll: false });
    },
  }
};
</script>
<style lang="scss" scoped>
.polygon-library {
  padding: 2rem;

  .filters-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .form-group {
      width: 19%;
    }

    .tags-container {
      border: none !important;
      outline: none !important;
      padding: 0 !important;
      width: 100%;
    }

    .tags-container.focus {
      box-shadow: none !important;
    }
  }

  .clear-button {
    margin-bottom: 1.5rem;
  }

  ::v-deep .dropdown-toggle {
    overflow:hidden;
  }

  ::v-deep .dropdown-menu {
    width:auto !important;
  }

  .selected-tags {
    margin-top:10px;
  }
}
</style>