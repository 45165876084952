<template>
  <div class="location-affinity-options">
    <div>
      <p>Location Affinity</p>
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex flex-column">
          <label for="previous">Previous</label>
          <div name="previous">
            <b-button @click="prevMonth" variant="outline-primary">Month</b-button>
            <b-button @click="prevYear" variant="outline-primary" class="ml-2">Year</b-button>
          </div>
        </div>
        <div class="d-flex flex-column">
          <label for="trailing">Trailing</label>
          <div name="trailing">
            <b-button @click="trailing30Days" variant="outline-primary">30 Days</b-button>
            <b-button @click="trailing60Days" variant="outline-primary" class="ml-2">60 Days</b-button>
            <b-button @click="trailing3Months" variant="outline-primary" class="ml-2">3 Months</b-button>
            <b-button @click="trailing6Months" variant="outline-primary" class="ml-2">6 Months</b-button>
            <b-button @click="trailing1Year" variant="outline-primary" class="ml-2">1 Year</b-button>
          </div>
        </div>
      </div>
      <div class="calendar-container">
        <div class="date-pickers">
          <div class="mb-2">
            Start Date
            <b-input-group>
              <b-form-input id="start-date-input" v-model="startDate" type="text" placeholder="YYYY-MM-DD"
                autocomplete="off"></b-form-input>
              <b-input-group-append>
                <b-form-datepicker v-model="startDate" :state="validDates.startDate || validDates.success" button-only
                  center locale="en-US" aria-controls="start-date-input" :min="earliestDate.format('YYYY-MM-DD')"
                  :max="twoDaysAgo"></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div>
            End Date
            <b-input-group>
              <b-form-input id="end-date-input" v-model="endDate" type="text" placeholder="YYYY-MM-DD"
                autocomplete="off"></b-form-input>
              <b-input-group-append>
                <b-form-datepicker v-model="endDate" button-only right locale="en-US" aria-controls="end-date-input"
                  :max="twoDaysAgo" :state="validDates.endDate || validDates.success"></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div>
            <p v-if="error !== ''" class="job-details-error mt-2">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="calendar-description">
          <p class="subtext">
            These dates are specific to the Location Affinity dataset. The
            start/end dates selected set the timeline used to track visitation
            to POI locations for study devices and baseline ("average") devices.
            Note that if no time frame is selected here, it will default to 1
            year ago for the Start Date, and today's date for the End Date and
            evaluate device visitation to POIs for that year to develop the
            dataset. The latest possible end date is two dates prior to when
            this job is scheduled to run. Data is available from January 1,
            2019. Please note that these times are in UTC.
          </p>
        </div>
      </div>
      <p class="mt-4">Use Regional Baseline</p>
      <div class="baseline-option d-flex">
        <p class="subtext" style="width: 50%">
          Instead of using a baseline of POI visitation by the "average
          American", this allows the specification of creating a different
          basket of consumers for comparison to your visitors.
        </p>
        <b-checkbox v-model="useRegionalBaseline" switch></b-checkbox>
      </div>
      <b-collapse v-model="useRegionalBaseline" class="regional-baseline-collapse">
        <RadioButtonList v-if="useRegionalBaseline" @change="updateRegionType" :options="regionalBaselineOptions"
          :default="regionalBaselineOptions[0]" ref="regionalBaselineSelector"></RadioButtonList>
        <div class="regional-baseline-container">
          <div class="regional-baseline-input">
            <b-form-group>
              <b-form-tags v-model="regionOptionTags" class="tags-container">
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag @remove="removeOption({ tag, removeTag })" :title="tag" :disabled="disabled"
                        variant="primary">
                        {{ tag }}
                      </b-form-tag>
                    </li>
                  </ul>
                  <b-dropdown class="region-options-dropdown" variant="outline-secondary" block menu-class="w-100">
                    <template #button-content>
                      {{ regionType }}
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => { }">
                      <b-form-input autofocus v-model="regionSearchTerm" type="search" autocomplete="off"
                        @input="updateRegionOptions()"></b-form-input>
                    </b-dropdown-form>
                    <b-dropdown-divider v-if="regionOptions.length > 0"></b-dropdown-divider>
                    <b-dropdown-item-button v-for="option in regionOptions" :key="option.id"
                      @click="onOptionClick({ option, addTag })">{{ option.name | str_limit(32)
                      }}</b-dropdown-item-button>
                  </b-dropdown>
                </template>
              </b-form-tags>
            </b-form-group>
          </div>
          <div class="regional-baseline-description">
            <p class="subtext">
              Optional: Enter specific geographies to use for baselining, or
              leave blank to auto-detect based on input location geographies.
            </p>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import RegionalBaseline from './RegionalBaseline.vue';
import RadioButtonList from "./shared/RadioButtonList.vue";
import VPService from "@/services/dataExplorer/vpService.js";
import DEConstants from "@/services/dataExplorer/constants.js";
import { bus } from "@/main";

export default {
  mixins: [VPService, DEConstants],
  components: {
    // RegionalBaseline,
    RadioButtonList,
  },
  mounted() {
    const {
      locationAffinityRegionType,
      regions,
    } = this.$sessionStore.state.jobRequest.locationAffinityReportOptions;
    if (locationAffinityRegionType !== undefined) {
      this.regionType = this.regionalBaselineOptions.filter(
        (option) => option.toUpperCase() === locationAffinityRegionType
      )[0];
      this.$refs.regionalBaselineSelector.setSelected(this.regionType);
    }
    if (regions !== undefined) {
      // Fetch all the regions from VP
      this.fetchRecreateRegions(regions);
    }
  },
  data() {
    return {
      startDate: this.$sessionStore.state.jobRequest.locationAffinityReportOptions.minDate,
      endDate: this.$sessionStore.state.jobRequest.locationAffinityReportOptions.maxDate,
      useRegionalBaseline: this.$sessionStore.state.jobRequest.locationAffinityReportOptions.useRegionalBaseline,
      regionSearchTerm: "",
      regionOptions: [],
      regionOptionTags: [],
      regionSearchDebounce: null,
      selectedRegions: [],
      regionType: "Country",
      error: "",
    };
  },
  beforeDestroy() {
    // this.$sessionStore.commit("setLocationAffinityReportOptions", Defaults.locationAffinityReportOptions.defaults);
  },
  methods: {
    // the 7 following methods all calculate the proper start and end dates based on the button pressed
    // e.g. prevMonth() sets the startDate as the first day of the last month and the endDate as the last day of the last month
    prevMonth() {
      this.startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    prevYear() {
      this.startDate = moment()
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .subtract(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    },
    trailing30Days() {
      this.startDate = moment().subtract(31, "days").format("YYYY-MM-DD");
      this.endDate = moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    trailing60Days() {
      this.startDate = moment().subtract(61, "days").format("YYYY-MM-DD");
      this.endDate = moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    trailing3Months() {
      this.startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      this.endDate = moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    trailing6Months() {
      this.startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
      this.endDate = moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    trailing1Year() {
      this.startDate = moment().subtract(1, "years").format("YYYY-MM-DD");
      this.endDate = moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    updateRegionType(regionType) {
      this.regionType = regionType;
      this.$sessionStore.commit(
        "setLocationAffinityRegionType",
        regionType.toUpperCase()
      );
      this.clearRegionSelections();
    },
    clearRegionSelections() {
      this.selectedRegions = [];
      this.regionSearchTerm = "";
      this.regionOptions = [];
      this.regionOptionTags = [];
    },
    removeOption({ tag, removeTag }) {
      removeTag(tag);
      this.selectedRegions = this.selectedRegions.filter(
        (option) => option.name !== tag
      );
    },
    updateRegionOptions() {
      if (this.regionSearchTerm === "") return;
      if (this.regionSearchDebounce !== null)
        clearTimeout(this.regionSearchDebounce);
      this.regionSearchDebounce = setTimeout(async () => {
        this.regionOptions = await this.searchCriteria(this.regionFilter, {
          name: this.regionSearchTerm,
        });
      }, 200);
    },
    async fetchRecreateRegions(regionKeys) {
      let result;
      if (this.regionType === "State") {
        const regions = [];
        for (const regionKey of regionKeys) {
          const splitted = regionKey.split("-");
          regions.push(splitted[1]);
          regions.push(splitted[0]);
        }
        result = await this.searchCriteria(this.regionFilter, {
          page_size: 10000,
          operator: "~=",
          key: regions.join("|"),
        });
        const matchRegions = regionKeys.map((regionKey) => {
          const split = regionKey.split("-");
          return `${split[1]}|${split[0]}`;
        });
        result = result.filter((criteria) => {
          return matchRegions.includes(criteria.key);
        });
      } else {
        result = await this.searchCriteria(this.regionFilter, {
          key: regionKeys.join("|"),
        });
      }

      for (const region of result) {
        this.selectedRegions.push(region);
        this.regionOptionTags.push(region.name);
      }
    },
    onOptionClick({ option, addTag }) {
      addTag(option.name);
      this.selectedRegions.push(option);
      this.regionSearchTerm = "";
      this.regionOptions = [];
    },
  },
  computed: {
    twoDaysAgo() {
      // calculates the date from two days ago, the upper limit for searchable timeframe
      return moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    locationAffinityOptions() {
      // formats data into object that will be sent to jobRequest store
      const { startDate, endDate, useRegionalBaseline, regionType } = this;
      return {
        minDate: startDate,
        maxDate: endDate,
        useRegionalBaseline,
        locationAffinityRegionType: regionType.toUpperCase(),
      };
    },
    regionalBaselineOptions() {
      return ["Country", "State", "DMA"];
    },
    validDates() {
      // checks that dates are valid
      const validStartDate = moment(
        this.startDate,
        "YYYY-MM-DD",
        true
      ).isValid();
      const validEndDate = moment(this.endDate, "YYYY-MM-DD", true).isValid();
      if (!validStartDate || !validEndDate) {
        return {
          success: false,
          startDate: validStartDate,
          endDate: validEndDate,
          message: "The dates must be in a valid format.",
        };
      }

      const twoDaysAgo = moment().subtract(2, "days");
      if (
        moment(this.startDate) > twoDaysAgo ||
        moment(this.endDate) > twoDaysAgo ||
        moment(this.startDate) > moment(this.endDate)
      ) {
        return {
          success: false,
          message: "The end date must be two days prior to the current date.",
        };
      }

      if (moment(this.startDate).isBefore(this.earliestDate, "day")) {
        return {
          success: false,
          message: "Earliest data is provided from 1/1/2019.",
        };
      }

      return { success: true };
    },
    regionFilter() {
      switch (this.regionType) {
        case "Country":
          return this.Filters[2];
        case "State":
          return this.Filters[3];
        case "DMA":
          return this.Filters[4];
        default:
          return this.Filters[2];
      }
    },
  },
  watch: {
    // updates jobRequest store when locationAffinityOptions data changes and if dates are valid
    locationAffinityOptions() {
      if (this.validDates.success) {
        this.error = "";
        bus.$emit("validationLog", {
          action: "remove",
          sender: this.$options.name
        });
        this.$sessionStore.commit(
          "setLocationAffinityReportOptions",
          this.locationAffinityOptions
        );
      } else {
        this.$sessionStore.commit("setLocationAffinityReportOptions", undefined);
        this.error = this.validDates.message;
        bus.$emit("validationLog", {
          action: "add",
          message: "Invalid date for Location Affinity Report options.",
          sender: this.$options.name
        });
      }
    },
    selectedRegions(regions) {
      if (regions.length > 0) {
        let finalRegions;
        if (this.regionType === "State") {
          finalRegions = regions.map((item) => {
            if (item.key.includes("|")) {
              const split = item.key.split("|");
              return `${split[1]}-${split[0]}`;
            }
          });
        } else finalRegions = regions.map((item) => item.key);
        this.$sessionStore.commit("setLocationAffinityRegions", finalRegions);
      } else {
        this.$sessionStore.commit("deleteLocationAffinityRegions");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.location-affinity-options {
  margin-bottom: 1.5em;
}

.calendar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .date-pickers {
    width: 25%;
    background-color: #f9fafc;
  }

  .calendar-description {
    width: 50%;

    p {
      margin: 0;
    }
  }
}

.job-details-error {
  color: red;
}

.regional-baseline-collapse {
  margin-top: 1rem;
}

.regional-baseline-container {
  display: flex;
  justify-content: space-between;

  .regional-baseline-input {
    width: 40%;

    .tags-container {
      border: none !important;
      outline: none !important;
      padding: 0 !important;
      width: 100%;
      background-color: #f9fafc;
    }

    .tags-container.focus {
      box-shadow: none !important;
    }
  }

  .regional-baseline-description {
    width: 50%;
  }
}

.baseline-option {
  width: 100%;
}
</style>