<template>
  <div
    class="d-flex placesDetailsCard"
    :class="{ placesDetailsCardCollapsed: isPlacesCollapsed }"
  >
    <div
      class="justify-content-between locations"
      :class="{ fixedPlacesCard: isFixed }"
      v-if="
        isLoading ||
        $route.name == 'study-map' ||
        $route.name == 'preview-charts'
      "
    >
      <div
        v-if="$route.name !== 'preview-charts' && $route.name !== 'preview-map'"
      >
        <!-- <div v-if="$route.name !== 'preview-map'"> -->
        <div
          class="
            d-flex
            align-items-start
            justify-content-between
            detailsCardLocation
          "
          v-for="(selectedLocation, index) in studyLocations"
          :key="selectedLocation.i"
          @click="clickSelectedLocation(selectedLocation, $event, index)"
        >
          <div
            v-if="
              $route.name !== 'preview-charts' && $route.name !== 'preview-map'
            "
            class="card d-flex flex-row location"
            @mouseover="hoverHandler(index, true)"
            @mouseleave="hoverHandler(index, false)"
          >
            <div class="d-flex align-items-center location-info">
              <b-skeleton-wrapper>
                <template #loading>
                  <b-skeleton
                    type="avatar"
                    width="48px"
                    height="48px"
                  ></b-skeleton>
                </template>
                <!-- getImgContainerClass(selectedLocation)  was removed here, we should fix the mixin later-->
                <div
                  class="brand-img brand-avatar brand-avatar-48 avatar-logo"
                  @click="toggleLocation(index, selectedLocation)"
                  :style="getImgContainerStyle(selectedLocation)"
                  :class="{ unSelected: !show[index], hoverable: hover[index] }"
                  :id="`tooltip-target-` + index"
                >
                  <img
                    :src="getCategoryAvatar(selectedLocation)"
                    alt=""
                    :style="getImgStyle(selectedLocation)"
                    class="selectedAvatar"
                  />
                  <img
                    :class="{ eye: !hover[index] || !show[index] }"
                    :src="require('pinnacle-lib/assets/icon/white-eye.svg')"
                  />

                  <img
                    :class="{ eye: !hover[index] || show[index] }"
                    :src="require('pinnacle-lib/assets/icon/white-no-eye.svg')"
                  />
                  <b-tooltip
                    :target="`tooltip-target-` + index"
                    triggers="hover"
                    placement="right"
                    boundary="parent"
                    animation="false"
                    interactive="false"
                  >
                    {{ show[index] ? "Hide Insights" : "Show Insights" }}
                  </b-tooltip>
                </div>
              </b-skeleton-wrapper>
              <div class="loc-name">
                <b-skeleton-wrapper>
                  <template #loading>
                    <b-skeleton
                      height="10px"
                      width="120px"
                      style="margin-top: 10px"
                    ></b-skeleton>
                  </template>
                  <p class="name">{{ selectedLocation.name | str_limit }}</p>
                </b-skeleton-wrapper>
                <b-skeleton-wrapper>
                  <template #loading>
                    <b-skeleton height="10px" width="120px"></b-skeleton>
                  </template>

                  <a
                    class="address"
                    @click="addressClicked(selectedLocation)"
                    >{{ selectedLocation.address | str_limit(32) }}</a
                  >
                </b-skeleton-wrapper>
              </div>
              <img
                style="cursor: pointer"
                class="placesCheckboxes"
                :src="require('pinnacle-lib/assets/icon/play-check.svg')"
                :id="`remove-target-` + index"
                alt="Remove location"
                @click="removeLocation($event, studyLocations[index])"
              />
              <b-tooltip
                :target="`remove-target-` + index"
                triggers="hover"
                placement="top-right"
              >
                Remove from comparison
              </b-tooltip>
              <!--<b-checkbox
              value="true"
              :checked="!(selectedLocation.id in disabledIds)"
              @change="toggleLocation($event, selectedLocation)"
              size="lg"
              class="placesCheckboxes"
            ></b-checkbox> -->
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          $route.name === 'study-charts' || $route.name === 'preview-charts'
        "
        class="selection"
      >
        <p class="description">Similar Place Average</p>
      </div>
      <div
        class="
          d-flex
          align-items-start
          justify-content-between
          detailsCardComparisonCharts
        "
        v-bind:class="{
          detailsCardComparisonMaps: $route.name === 'study-map',
        }"
        v-if="
          (isLoading &&
            $route.name !== 'study-map' &&
            $route.name !== 'preview-map') ||
          $route.name === 'preview-charts'
        "
        @mouseover="hoverHandler('avg', true)"
        @mouseleave="hoverHandler('avg', false)"
      >
        <div class="card d-flex flex-row justify-content-between location">
          <b-skeleton-wrapper>
            <template #loading>
              <b-skeleton type="avatar" width="14px" height="14px"></b-skeleton>
            </template>
          </b-skeleton-wrapper>
          <div class="d-flex align-items-center location-info average">
            <b-skeleton-wrapper>
              <template #loading>
                <b-skeleton
                  type="avatar"
                  width="48px"
                  height="48px"
                ></b-skeleton>
              </template>
              <div
                class="brand-img brand-avatar brand-avatar-48 avatar-logo"
                :style="getImgContainerStyle(true)"
                :class="{
                  unSelected: !status,
                  selected: status,
                  hoverable: avgHover,
                }"
                id="tooltip-target-avg"
                @click="check(!status)"
              >
                <img
                  :src="avgImgUrl"
                  alt="avatar"
                  height="48px"
                  width="48px"
                  id="comparisonAvatar"
                />
                <img
                  :class="{ eye: !avgHover || !status }"
                  :src="require('pinnacle-lib/assets/icon/white-eye.svg')"
                />

                <img
                  :class="{ eye: !avgHover || status }"
                  :src="require('pinnacle-lib/assets/icon/white-no-eye.svg')"
                />
                <b-tooltip
                  target="tooltip-target-avg"
                  triggers="hover"
                  placement="right"
                  boundary="parent"
                  animation="false"
                  interactive="false"
                >
                  {{ status ? "Hide Average" : "Show Average" }}
                </b-tooltip>
              </div>
            </b-skeleton-wrapper>

            <div class="loc-name">
              <b-skeleton-wrapper>
                <template #loading>
                  <b-skeleton
                    height="10px"
                    width="120px"
                    style="margin-top: 10px"
                  ></b-skeleton>
                </template>
                <h2 class="name avgName">
                  Avg. {{ comparisonGroupSubSector }}
                </h2>
              </b-skeleton-wrapper>
              <p class="comparisonPar">{{ comparisonGroupMunicipality }}</p>
            </div>
          </div>
        </div>
      </div>

      <!---- end comparison ---->
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  data() {
    return {
      modalShow: false,
      status: false,
      range: true,
      isFixed: false,
      isPlacesCollapsed: false,
      uncheckedLocationId: null,
      isLoading: false,
      commonElement: [],
      hover: [false, false, false, false, false],
      avgHover: false,
      show: [true, true, true, true, true],
      avgShow: this.$sessionStore.state.study.bool,
    };
  },
  mounted() {
    // this.getComparisonData();
    this.$sessionStore.commit("setAvg", ``);
    this.getComparisonData().then(() => {
      if (this.average) {
        this.status = this.average;
      }
      //console.log(JSON.stringify(this.studyLocations));
      //Set bools if disabled
      for (let i = 0; i < this.studyLocations.length; i++) {
        if (this.disabledIds[this.studyLocations[i].id] !== undefined) {
          this.$set(this.show, i, false);
        }
      }

      bus.$off("collapsePlacesCard");
      bus.$on("collapsePlacesCard", () => {
        this.isPlacesCollapsed = !this.isPlacesCollapsed;
        bus.$emit("resizeMap");
      });

      bus.$off("togglePins");
      bus.$on("togglePins", (status, location) => {
        this.toggleLocation(status, location);
      });
    });
    // setTimeout(this.getComparisonData, 100);

    // setTimeout(() => this.check(), 3000);
  },
  computed: {
    average() {
      return this.$sessionStore.state.study.bool;
    },
    studyLocations() {
      let allSelectedLocations =
        this.$route.path.split("/")[1] === "preview"
          ? this.$sessionStore.state.study.previews
          : this.$sessionStore.state.study.locations;
      return allSelectedLocations;
    },
    comparisonGroupMunicipality() {
      // if (this.commonElement.geography === undefined) {
      if (!this.commonElement.geography) {
        this.$sessionStore.commit("setAvgAddress", "");
        return " ";
      } else {
        let locationMunicipality = this.commonElement.geography;
        this.$sessionStore.commit("setAvgAddress", locationMunicipality.value);
        return locationMunicipality.value;
      }
    },
    comparisonGroupSubSector() {
      // let locationSubSector =  this.commonElement.category;
      //  if (locationSubSector === undefined){
      if (!this.commonElement.category) {
        this.$sessionStore.commit("setAvg", `Avg. place`);
        return " place";
      } else {
        let locationSubSector = this.commonElement.category;
        this.$sessionStore.commit("setAvg", `Avg. ${locationSubSector.value}`);
        return locationSubSector.value;
      }
    },
    avgImgUrl() {
      // if (this.commonElement.category === undefined) {
      if (!this.commonElement.category) {
        return require("pinnacle-lib/assets/icon/avg-pin.svg");
      }
      //avatars for preview route
      else if (this.$route.path.split("/")[1] === "preview") {
        const avatarSubsectorPreview =
          this.$sessionStore.state.study?.previews[0]?.avatar_subsector;
        const avatarSectorPreview =
          this.$sessionStore.state.study?.previews[0]?.avatar_sector;
        return avatarSubsectorPreview && avatarSubsectorPreview !== "None"
          ? avatarSubsectorPreview
          : avatarSectorPreview;
      } else {
        const avatarSubsector =
          this.$sessionStore.state.study.locations[0].avatar_subsector;
        const avatarSector =
          this.$sessionStore.state.study.locations[0].avatar_sector;

        if (
          this.commonElement.category.name === "category" &&
          avatarSector !== "None"
        ) {
          return !avatarSubsector || avatarSubsector === "None"
            ? avatarSector
            : avatarSubsector;
        } else if (
          this.commonElement.category.name === "sub_sector" &&
          avatarSector !== "None"
        ) {
          return !avatarSubsector || avatarSubsector === "None"
            ? avatarSector
            : avatarSubsector;
        } else if (this.commonElement.category.name === "sector") {
          return !avatarSector || avatarSector === "None"
            ? require("pinnacle-lib/assets/icon/avg-pin.svg")
            : avatarSector;
        } else {
          return require("pinnacle-lib/assets/icon/avg-pin.svg");
        }
      }
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
  },
  watch: {},
  methods: {
    removeLocation(val, location) {
      // this.addButtonShow = !this.addButtonShow;
      const newLocations = this.studyLocations.filter(
        (loc) => loc.id !== location.id
      );

      const oldIds = this.$route.params.ids.split(",");

      this.$router.push({
        params: { ids: oldIds.filter((id) => id != location.id).join(",") },
        name: this.$route.name,
      });

      this.$sessionStore.commit("setLocations", newLocations);

      if (this.studyLocations.length === 0) {
        this.$router.push({ path: "/explore" });
        return;
      }

      //adding location.reload becasue charts dont have re-render functionality yet
      window.location.reload();

      //to update charts
      // bus.$emit("locationsListChanged", {
      //   selected: val,
      //   locationId: location.id,
      // });

      bus.$emit("updateMapMarkersOn", location);
    },
    hoverHandler(index, state) {
      if (index === "avg") {
        this.avgHover = state;
        return;
      }
      this.$set(this.hover, index, state);
    },
    async getComparisonData() {
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        const res = await this.$http.get(
          `/v2/location-details/${this.$route.params.ids}`,
          headers
        );
        this.commonElement = res.data.meta_data.common_element;
        this.isLoading = true;
      } catch (err) {
        console.log("ERROR", err);
        if (!err.response.data.success && !err.response.data.statusCode) {
          await this.refreshToken();
          this.getComparisonData();
        }
      }
    },
    check(bool) {
      this.status = bool;
      this.$sessionStore.commit("setShowMedian", bool);
      this.$nextTick(() => {
        bus.$emit("locationsListChanged", {
          selected: bool,
          locationId: "median",
        });
      });
    },
    remove() {
      this.$nextTick(() => {
        bus.$emit("locationsListChanged", {
          selected: this.range,
          locationId: "range",
        });
      });
    },
    addressClicked(selectedLocation) {
      if (this.$route.name === "study-map") {
        bus.$emit("flyTo", {
          center: selectedLocation.centroid.coordinates,
        });
      } else {
        this.$router.push({
          path: `/study/${this.studyLocations
            .map((loc) => loc.id)
            .join(",")}/map`,
          query: {
            center: JSON.stringify(selectedLocation.centroid.coordinates),
          },
        });
      }
    },
    clickSelectedLocation(selectedLocation, event, index) {
      if (
        event.target.className !== "name" ||
        event.target.className === "custom-control-input" ||
        event.target.className === "address" ||
        event.target.className ===
          "brand-img brand-avatar brand-avatar-48 avatar-logo" ||
        event.target.localName === "img" ||
        event.target.className ===
          "brand-img brand-avatar brand-avatar-48 avatar-logo unSelected hoverable" ||
        event.target.className ===
          "brand-img brand-avatar brand-avatar-48 avatar-logo hoverable"
      ) {
        //This just ends the function instead of navigating to the details card
      } else {
        let bool =
          this.disabledIds[selectedLocation.id] === undefined ? true : false;
        if (this.$route.path.split("/")[3] === "charts")
          this.$router.push({ path: `charts/details/${index},${bool}` });
        else {
          this.$router.push({
            path: "map/details",
            query: { place: btoa(JSON.stringify(selectedLocation)) },
          });
        }

        // this.uncheckedLocationId = selectedLocation.id;
        // this.$nextTick(() => {
        //   bus.$emit("detailsSelected", {
        //     bool: this.disabledIds[selectedLocation.id],
        //     index: index,
        //   });
        // });
      }
    },
    toggleLocation(index, location) {
      this.checked = !this.checked;
      const disabledIds = Object.assign({}, this.disabledIds);
      if (this.show[index]) disabledIds[location.id] = true;
      else delete disabledIds[location.id];

      this.$sessionStore.commit("setDisabledIds", disabledIds);

      // a function to emit selectedLocation to Charts to remove it
      bus.$emit("locationsListChanged", {
        selected: !this.show[index],
        locationId: location.id,
      });
      this.$set(this.show, index, !this.show[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
@for $i from 1 through length($location-colors) {
  $color: nth($location-colors, $i);
  .detailsCardLocation:nth-child(#{$i}) {
    ::v-deep
      .placesCheckboxes
      > .custom-control-input:checked
      ~ .custom-control-label::before {
      background-color: $color !important;
      border-color: $color !important;
    }
  }
}
@for $i from 1 through length($location-colors) {
  $color: nth($location-colors, $i);
  .detailsCardLocation:nth-child(#{$i}) {
    ::v-deep .custom-control-label::after {
      border: $color solid 2px !important;
      top: 0.3125rem;
      left: -1.875rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.3rem;
    }
  }
}
// to fade avatars on hover
.avatar-logo:hover {
  .selectedAvatar {
    opacity: 0.45;
  }
}
.checkbox {
  ::v-deep .custom-control-label::after {
    border: #6e84a3 solid 1px !important;
    top: 0.3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
  }
}
.checkbox {
  ::v-deep > .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #6e84a3 !important;
    border-color: #6e84a3 !important;
  }
}

.avgName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.placesDetailsCard {
  flex-direction: column;
  border-top: 0px;
  width: 100%;
}
.placesDetailsCardCollapsed {
  width: 80px;
  .placesCheckboxes {
    display: none !important;
  }
  .checkbox {
    display: none;
  }
}
.collapsePlaces {
  bottom: 0px !important;
  display: flex;
  margin-top: 90px;
}
.collapsePlacesBtn {
  cursor: pointer;
  margin-left: 22px;
}
.brand-avatar-48 img {
  object-fit: contain;
}
.location .loc-name {
  white-space: nowrap;

  text-overflow: ellipsis;
}

.brand-img-comparison {
  border-radius: 0.5rem;
  background-color: #edf2f6;
  position: relative;
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-origin: content-box !important;
  vertical-align: middle;
  width: 48px;
  height: 48px;
}

.brand-img:hover {
  cursor: pointer;
}

.checkbox {
  margin-top: -8px;
  position: absolute;
  right: 30px;
}

#comparisonAvatar {
  filter: invert(55%) sepia(40%) saturate(294%) hue-rotate(175deg)
    brightness(86%) contrast(87%);
}
.placesDetailsAvatar {
  background-color: white;
  padding: 4px;
}
::v-deep .locations {
  padding: 0px 0px;
  position: relative;

  .detailsCardLocation {
    height: 80px;
    display: flex;
    align-items: center !important;
    background-color: #ffffff;
    border-bottom: 1px solid #e3ebf6;
  }
  .detailsCardLocationMaps {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    border-bottom: 1px solid #e3ebf6;
  }
  .detailsCardNameMaps {
    border: none;
    box-shadow: none !important;
  }
}
.placesCheckboxes {
  position: absolute;
  right: 30px;
}
@media only screen and (max-width: 1380px) {
  .location-info .brand-img .brand-img-comparison {
    display: none;
  }
  .placesDetailsCardCollapsed .location-info .loc-name {
    display: none;
  }
}

.detailsCardComparisonCharts {
  height: 81px;
  display: flex;
  align-items: center !important;
  background-color: #ffffff;
  // border-bottom: 1px solid #e3ebf6;
}

.placesPar {
  font-size: 15px;
  font-weight: 500;
  color: #1f2d3d;
}
.managePlacesPar {
  font-size: 13px;
  color: #2c7be5;
}
::v-deep .location {
  background: none;
  border-radius: 0px;
  padding: 17px;
  height: 48px;
  width: 100%;
  border: none;
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-bottom: 0px;
  .loc-name {
    font-size: 15px;
    color: #1f2d3d;
    margin-bottom: 0px;
    width: 250px;
    margin-left: 15px;
    .address {
      margin-bottom: 0px;
      font-size: 14px;
      color: #6e84a3;
      cursor: pointer;
      font-weight: bold;
    }

    .address:hover {
      text-decoration: underline;
    }

    .comparisonPar {
      margin-bottom: 0px;
      font-size: 13px;
      color: #6e84a3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: bold;
      color: #253561;
    }
  }
}
.location-info .address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selection {
  display: flex;
  background: #f9fafc;
  padding: 18px 24px;
  justify-content: space-between;
  flex-direction: row;
  // border-bottom: 1px solid $border-color;
}
.description {
  margin-bottom: 0px;
  color: #6e84a3;
  font-weight: 700;
  font-size: 14px;
}
.edit {
  margin-bottom: 0px;
  font-size: 14px;
  color: #6e84a3;
  font-weight: normal;
}
.eye {
  display: none;
}
.unSelected {
  border: 4px solid #e3ebf6 !important;
}
.hoverable {
  background: rgba(22, 34, 68, 0.5) !important;
}
.selected {
  border: 4px solid #6e84a3;
  border-radius: 4px;
}

@for $i from 1 through length($location-colors) {
  $color: nth($location-colors, $i);
  $bgcolor: nth($location-bg-colors, $i);
  $filter: nth($location-filter-colors, $i);
  .detailsCardLocation:nth-child(#{$i}) {
    .brand-avatar {
      border: 4px solid $color;
      &.fallback-icon {
        background-color: $bgcolor !important;
        img {
          filter: $filter !important;
        }
      }
    }
  }
}

@media only screen and (max-height: 1000px) {
  .placesDetailsCard {
    margin-bottom: 130px;
  }
}
</style>