<template>
  <div class="time-period-container">
    <div class="time-period">
      <div>
        <p>Time Period</p>
        <div class="d-flex">
          <div class="d-flex align-items-center buttons">
            <b-button
              variant="outline-primary"
              class=""
              size="sm"
              @click="buttonPress(600)"
              >10 mins</b-button
            >
            <b-button
              variant="outline-primary"
              class="ml-2"
              size="sm"
              @click="buttonPress(1200)"
              >20 mins</b-button
            >
            <b-button
              variant="outline-primary"
              class="ml-2"
              size="sm"
              @click="buttonPress(1800)"
              >30 mins</b-button
            >
            <b-button
              variant="outline-primary"
              class="ml-2"
              size="sm"
              @click="buttonPress(3600)"
              >1 hour</b-button
            >
            <b-button
              variant="outline-primary"
              class="ml-2"
              size="sm"
              @click="buttonPress(7200)"
              >2 hours</b-button
            >
          </div>
          <!-- <div class="explanation subtext ml-5">
        <slot name="description"></slot>
      </div> -->
        </div>
        <div class="d-flex">
          <div class="calculator-field ml-5">
            <p class="required">*Required</p>
            <p>Before</p>
            <SecondsCalculator
              @submitTotalSeconds="setSeconds"
              :buttonPressCheck="buttonPressCheck"
              :buttonInput="buttonInput"
              :role="'before'"
              :id="id"
              ref="beforeSecondsCalculator"
              :presetSeconds="presetSecondsBefore"
            />
          </div>
          <div class="calculator-field ml-8">
            <p class="required">*Required</p>
            <p>After</p>
            <SecondsCalculator
              @submitTotalSeconds="setSeconds"
              :buttonPressCheck="buttonPressCheck"
              :buttonInput="buttonInput"
              :role="'after'"
              :id="id"
              ref="afterSecondsCalculator"
              :presetSeconds="presetSecondsAfter"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="helper-text">
      <div v-if="id === 'pathing-report-options' || id === 'ogs-report-options' || id === 'dwell-time-report-options'">
        <p>
          Find out where a customer was before or after they visited a location
          by designating a time frame in which to look for them. These two
          values do not have to be the same, but must be any whole number
          greater than or equal to 0.
        </p>
        <p class="mt-2">
          <span class="boldText">Please note:</span> Seconds Before and After
          are limited to one month time periods.
        </p>
      </div>
      <div v-if="id === 'cluster-report-options'">
        <p>
          Enter time frames for both before and after to determine how far
          back/forward to look after a visit to determine clusters. The two
          values do not have to be the same, but must be any whole number
          greater than or equal to 0.
        </p>
        <p class="mt-2">
          <span class="boldText"> Please note: </span>Seconds Before and After
          are limited to one month time periods.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SecondsCalculator from "./SecondsCalculator.vue";

export default {
  components: {
    SecondsCalculator,
  },
  props: ["id", "presetSecondsBefore", "presetSecondsAfter"], // need to pass an id down to make sure the calculators are all independent
  data() {
    const before = this.presetSecondsBefore || 0;
    const after = this.presetSecondsAfter || 0;
    return {
      beforeSeconds: before,
      afterSeconds: after,
      buttonInput: 0,
      buttonPressCheck: 0,
    };
  },
  mounted() {
    this.$emit("timePeriodSeconds", this.seconds);
  },
  methods: {
    setSeconds(totalSeconds, role) {
      // listens for submitTotalSeconds event and updates the before/after seconds value here
      if (role === "before") {
        this.beforeSeconds = parseInt(totalSeconds);
        this.$refs.beforeSecondsCalculator.setSubmitValue(
          parseInt(totalSeconds)
        );
      }
      if (role === "after") {
        this.afterSeconds = parseInt(totalSeconds);
        this.$refs.afterSecondsCalculator.setSubmitValue(
          parseInt(totalSeconds)
        );
      }
    },
    buttonPress(seconds) {
      // updates seconds before and after with button press amount
      this.buttonPressCheck++; // increments to change buttonPressCheck prop in Seconds Calculator so that watcher on that component always fires with button press
      this.buttonInput = seconds;
    },
  },
  computed: {
    seconds() {
      // links beforeSeconds and afterSeconds so that watcher can emit event if either value changes
      const { beforeSeconds, afterSeconds } = this;
      return {
        beforeSeconds,
        afterSeconds,
      };
    },
  },
  watch: {
    seconds() {
      // emits event with before and after seconds payload
      this.$emit("timePeriodSeconds", this.seconds);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-period-container {
  display: flex;
  flex-direction: row;
}

.boldText {
  font-weight: 600 !important;
  color: black !important;
}
.helper-text {
  font-size: 14px;
  color: lighten(grey, 10%);
  width: 50%;
}

.time-period {
  width: 100%;
}
.buttons {
  flex-wrap: wrap;
}
.explanation {
  width: 40%;
  font-size: 12px;
}
.calculator-field {
  margin-top: 10px;
}
</style>