<template>
  <div class="base-maps">
    <div
      class="baseMapTile"
      @click="selected = 1"
      :class="{ selectedMap: selected == 1 }"
    >
      <img :src="require('pinnacle-lib/assets/icon/basemap-light.svg')" alt="Map icon" />
      <img
        id="checkbox"
        :src="require('pinnacle-lib/assets/icon/basemap-checkbox.svg')"
        alt="Map checkbox"
      />
      <span class="caption">Light</span>
    </div>
    <div
      class="baseMapTile"
      @click="selected = 2"
      :class="{ selectedMap: selected == 2 }"
    >
      <img :src="require('pinnacle-lib/assets/icon/basemap-satellite.svg')" alt="Map icon" />
      <img
        id="checkbox"
        :src="require('pinnacle-lib/assets/icon/basemap-checkbox.svg')"
        alt="Map checkbox"
      />
      <span class="caption">Satellite</span>
    </div>
    <div v-if="false"
      class="baseMapTile"
      @click="selected = 3"
      :class="{ selectedMap: selected == 3 }"
    >
      <img :src="require('pinnacle-lib/assets/icon/basemap-streets.svg')" alt="Map icon" />
      <img
        id="checkbox"
        :src="require('pinnacle-lib/assets/icon/basemap-checkbox.svg')"
        alt="Map checkbox"
      />
      <span class="caption">Streets</span>
    </div>
    <div
      class="baseMapTile"
      @click="selected = 4"
      :class="{ selectedMap: selected == 4 }"
    >
      <img :src="require('pinnacle-lib/assets/icon/basemap-dark.svg')" alt="Map icon" />
      <img
        id="checkbox"
        :src="require('pinnacle-lib/assets/icon/basemap-checkbox.svg')"
        alt="Map checkbox"
      />
      <span class="caption">Dark</span>
    </div>
    <div v-if="false"
      class="baseMapTile"
      @click="selected = 5"
      :class="{ selectedMap: selected == 5 }"
    >
      <img :src="require('pinnacle-lib/assets/icon/basemap-pirate.svg')" alt="Map icon" />
      <img
        id="checkbox"
        :src="require('pinnacle-lib/assets/icon/basemap-checkbox.svg')"
        alt="Map checkbox"
      />
      <span class="caption">Pirate</span>
    </div>
  </div>
</template>

<script>


export default {
  name: 'BasemapsModal',
  props: ["initialSelected"],
  data() {
    return {
      selected: 1
    };
  },
  watch: {
    selected(newVal, oldVal) {
      this.$emit("mapUpdated", {lastSelected: oldVal, selected: newVal});
    },
  },
  mounted() {
    if (this.initialSelected) {
      this.selected = this.initialSelected;
    } else {
      this.selected = 1;
    }
    // let lastSelectedMap = window.localStorage.getItem('lastSelectedMap');
    // if (lastSelectedMap == null) {
    //   this.selected = 1;
    // } else {
    //   lastSelectedMap = JSON.parse(lastSelectedMap);
    //   if (lastSelectedMap.selected !== this.selected)
    //     this.selected = lastSelectedMap.selected;
    // }
  },
};
</script>
<style lang="scss" scoped>
.base-maps {
  padding: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  #checkbox {
    visibility: hidden;
  }
}
.modal-lg {
  max-width: auto !important;
}

.modal-dialog {
  height: 60px;
  display: flex;
  .modal-header {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  h5 {
    font-size: 15px;
    font-weight: 500;
  }
  .close {
    font-weight: lighter;
    color: #b1c2d9;
    font-size: 32px;
    margin-top: -1rem;
  }
}

.baseMapTile {
  cursor: pointer;
  margin: 0;
}

.baseMapTile:hover {
  .caption {
    color: black;
  }
}

.base-maps img {
  padding: 5px;
  height: 91px;
  width: 98px;
  margin: 5px;
}

.base-maps .caption {
  display: block;
  color: #6e84a3;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  margin-bottom: 5px;
}

.selectedMap {
  background-color: #2c7be5;
  border-radius: 6px;

  .caption {
    color: white;
  }
  #checkbox {
    visibility: visible;
  }
}
.selectedMap:hover {
  .caption {
    color: white;
  }
}
#checkbox {
  position: absolute;
  width: 32px;
  height: 32px;
  margin-top: 12px;
  margin-left: -39px;
}
</style>
